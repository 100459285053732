import {searchClient} from "../lib/Algolia"
import {search_index} from "../data/globalsearchdata.json"
import {get_objectkeys} from "../utils/object"
function AlgoliaSearcher(search,indexName,filters='',page=0) {
    filters=search_index[indexName].skipuser_filter?'':filters
    const index = searchClient.initIndex(indexName);
    return index.search(search,{
        filters: filters,page:page
      })
    
}
export {AlgoliaSearcher}