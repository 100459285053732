import React, { useEffect, useState } from "react";
import DataSearch from "../layout/DataSearch";
import http from '../../providers/http'
function GlobalSearch({
  searchdata = "",
  indexName,
  datahandler,
  filter = "",
  page = 0,
}) {
  let [searchvalue, setSearchValue] = useState(searchdata);

  let searchtexthandler = (search) => {
    // let searchresult = AlgoliaSearcher(
    //   search,
    //   indexName,
    //   filter ? filter : "",
    //   page
    // );
    let searchresult = http
    .get("globalsearch", {
      params: {
        query: search,
        indexName: indexName,
        filter:filter ? filter : "", 
        page:page
      },
    })
    .then((result) => {
      return result.data
    })
    .catch((e)=>{
        console.error(e.message,'error in global_search')
      })
    datahandler(searchresult); // prop function to handle index data 
  };
  useEffect(() => {
    searchtexthandler(searchdata);
  }, []);
  useEffect(() => {
    searchtexthandler(searchdata);
  }, [page]);
  return (
    <div>
      <DataSearch
        childcomponent={
          <input
            className="form-control"
            name={indexName}
            id="search"
            value={searchvalue}
            type="text"
            onChange={(e) => {
              setSearchValue(e.target.value);
             
            }}
            onSubmit={(e)=>{console.log('submit action'); searchtexthandler(e.target.value);}}
          />
        }
      />
    </div>
  );
}

export default GlobalSearch;
