import http from "../../providers/http";
import auth from "../../providers/auth";
import Header from "../../providers/common/header";
import Help from "../../providers/common/help";
import Popover from "../../providers/common/Popover";
import Navbar from "../../providers/common/navbar";
import appconfig from "../../config";
import RepairsLoader from "../../providers/common/loaders";
import Paginator from "../../providers/common/paginator";
import Repairclaims from "../repair-claims/Repairclaims";
import React from 'react';
import ReactDOM from 'react-dom';
import ErrorPopup from "../../providers/common/ErrorPopup"
import DateConversion from "../../providers/common/Components/DateConversion";
import DataPagination from "../../rumodules/layout/DataPagination";
import {pagenumberslist} from "../../rumodules/utils/misc"
const BASE_URL = appconfig.BASE_URL;

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      dataList: "",
      showCompanies: "",
      companieslist: "",
      showData: false,
      resellerCompanies: "",
      currentCompany: "",
      showResellerCompanies: false,
      userRole: auth.userRole(),
      userType: auth.userType(),
      warrantyStatus: "",
      repairStartDate: "",
      repairEndDate: "",
      totalRecords: 0,
      pageLimitStart: 0,
      totalPages: 0,
      brands: [],
      getBrand: false,
      servererror: false,
    };
    this.handleserverError = this.handleserverError.bind(this);
    this.getRepairs = this.getRepairs.bind(this);
    this.selectCompany = this.selectCompany.bind(this);
    this.pageLimit = appconfig.PAGE_LIMIT; //Number of Records to show on a Page
    this.handlePaginationClick = this.handlePaginationClick.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.startStandaloneClaim = this.startStandaloneClaim.bind(this);
    this.handleCompanySelect = this.handleCompanySelect.bind(this);
  }
  handleserverError(e) {
    if (e == 'close') {
      this.setState({
        servererror: false
      })
    }
  }

  clearFilters(e) {
    window.localStorage.setItem("_crc_warrantyStatus", "");
    window.localStorage.setItem("_crc_repairStartDate", "");
    window.localStorage.setItem("_crc_repairEndDate", "");
    window.localStorage.setItem("_crc_companyFilters", "");
    window.localStorage.setItem("_crc_userFilters", "All");
    window.location.href = BASE_URL;
  }

  startStandaloneClaim() {
    const session_id = auth.isLoggedIn();
    http
      .get("companyShorts", {
        params: {
          session_id,
        },
      })
      .then(({ data }) => {
        data.sort((a, b) => a.company_name.localeCompare(b.company_name));
        this.setState({ brands: data }, () => {
          this.setState({ getBrand: true });
        });
      })
      .catch((err) => {
        this.setState({ servererror: err })
        console.log(err)
      });
  }
  handleCompanySelect(e) {
    this.setState({ selectedCompany: e.target.value }, () => {
      this.startStandaloneClaimAfterBrand();
    });
  }
  startStandaloneClaimAfterBrand() {
    const session_id = auth.isLoggedIn();
    const companyFilter = this.state.selectedCompany;
    http
      .post("startstandaloneclaim", {
        session_id,
        companyFilter,
      })
      .then((res) => {
        window.location.href =
          BASE_URL + "/repair.html?repair_id=" + res.data.repair_id;
      })
      .catch((error) => {
        this.setState({ servererror: error })
        auth.setLogin("", "");
        if (error.response.status === 401) {
          auth.setLogin("", "");
          window.location.href = BASE_URL + "/login.html";
        }
      });
  }

  getRepairs() {
    this.setState({
      showData: false,
    });
    const session_id = auth.isLoggedIn();
    let warrantyStatusEL = "";
    let brandSelctedEL = "";
    let repairStartDateEL = "";
    let repairEndDateEL = "";
    let warrantyStatus = "";
    let companyFilter = "";
    let userFilter="";
    let repairStartDate = "";
    let repairEndDate = "";
    let currentPage = 0;
    let pageLimit = this.pageLimit;
    let pageLimitStart = 0;
    let repairNumber;
    pageLimitStart = Number(this.state.pageLimitStart * pageLimit);
    warrantyStatusEL = document.getElementById("warranty_status");
    repairNumber = document.getElementById("repair_number")
    if (repairNumber) {
      repairNumber = repairNumber.value
    }
    if (warrantyStatusEL) {
      warrantyStatus =
        warrantyStatusEL.options[warrantyStatusEL.selectedIndex].value;
      window.localStorage.setItem("_crc_warrantyStatus", warrantyStatus);
    }
    brandSelctedEL = document.getElementById("brand_selected");
    if (brandSelctedEL) {
      companyFilter =
        brandSelctedEL.options[brandSelctedEL.selectedIndex].value;
      window.localStorage.setItem("_crc_companyFilters", companyFilter);
    }
    repairStartDateEL = document.getElementById("repair_start_date");
    if (repairStartDateEL) {
      repairStartDate = repairStartDateEL.value;
      window.localStorage.setItem("_crc_repairStartDate", repairStartDate);
    }
    repairEndDateEL = document.getElementById("repair_end_date");
    if (repairEndDateEL) {
      repairEndDate = repairEndDateEL.value;
      window.localStorage.setItem("_crc_repairEndDate", repairEndDate);
    }
    if(document.getElementById("user_filter")){
      userFilter=document.getElementById("user_filter").value;
    }

    warrantyStatus = warrantyStatus
      ? warrantyStatus
      : window.localStorage.getItem("_crc_warrantyStatus");
    companyFilter = companyFilter
      ? companyFilter
      : window.localStorage.getItem("_crc_companyFilters");
    repairStartDate = repairStartDate
      ? repairStartDate
      : window.localStorage.getItem("_crc_repairStartDate");
    repairEndDate = repairEndDate
      ? repairEndDate
      : window.localStorage.getItem("_crc_repairEndDate");
      userFilter=userFilter?userFilter:window.localStorage.getItem("_crc_userFilters")
      userFilter=document.getElementById('user_filter') ? document.getElementById('user_filter').value:userFilter;
    this.setState({
      warrantyStatus,
      repairStartDate,
      repairEndDate,
      companyFilter,
      userFilter
    });
    // console.log(companyFilter)
    http
      .get("getrepairs/", {
        params: {
          session_id,
          warrantyStatus,
          repairStartDate,
          repairEndDate,
          pageLimit,
          pageLimitStart,
          companyFilter,
          repairNumber,
          userFilter
        },
      })
      .then((res) => {
        // console.log(res.data)
        if (res.data.selectCompany) {
          this.setState({
            showCompanies: true,
            companieslist: res.data.userCompanies,
          });

          return;
        }
        // console.log(res.data)
        this.setState({
          dataList: res.data.respData,
          resellerCompanies: res.data.resellerCompanies,
          currentCompany: res.data.company_id,
          totalRecords: res.data.totalRecords,
          totalPages: res.data.totalRecords
            ? Math.ceil(res.data.totalRecords / this.pageLimit)
            : 0,
          showData: true,
        });

        let _crc_repairStartDate = window.localStorage.getItem(
          "_crc_repairStartDate"
        );
        if (_crc_repairStartDate) {
          document.getElementById("repair_start_date").value =
            _crc_repairStartDate;
        }
        if(document.getElementById('user_filter')){
          document.getElementById('user_filter').value=userFilter?userFilter:'All'
        }

        let _crc_repairEndDate =
          window.localStorage.getItem("_crc_repairEndDate");
        if (_crc_repairEndDate) {
          document.getElementById("repair_end_date").value = _crc_repairEndDate;
        }

        let _crc_warrantyStatus = window.localStorage.getItem(
          "_crc_warrantyStatus"
        );
        if (_crc_warrantyStatus) {
          let warrantyStatusEL = document.getElementById("warranty_status");
          warrantyStatusEL.options[warrantyStatusEL.selectedIndex].value =
            _crc_warrantyStatus;
        }
        let _crc_companyFilter =
          window.localStorage.getItem("_crc_companyFilter");
        if (_crc_warrantyStatus) {
          let brandSelctedEL = document.getElementById("brand_selected");
          brandSelctedEL.options[brandSelctedEL.selectedIndex].value =
            _crc_companyFilter;
        }

        return;
      })
      .catch((error) => {
        this.setState({ servererror: error })
        console.log(error);
        if (error.response.status === 401) {
          //empty user session
          auth.setLogin("", "");
          window.location.href = BASE_URL + "/login.html";
        }
      });

    http
      .get("companyShorts", {
        params: {
          session_id,
          isFilter: true,
        },
      })
      .then(({ data }) => {
        data.sort((a, b) => a.company_name.localeCompare(b.company_name));
        this.setState({ brands: data });
      })
      .catch((err) => {
        this.setState({ servererror: err })
        console.log(err)
      });
  }

  handlePaginationClick(page) {
    this.setState({ pageLimitStart: page }, () => this.getRepairs());
  }

  selectCompany(e) {
    const company_id =
      e.target.attributes.getNamedItem("data-company-id").value;

    this.setState({ userCompany: company_id, showCompanies: false });
    const session_id = auth.isLoggedIn();

    http
      .post("user/resellercompany/", {
        company_id,
        session_id,
      })
      .then((res) => {
        window.location.href = BASE_URL + "/";
      })
      .catch((error) => {
        this.setState({ servererror: error })
        auth.setLogin("", "");
        if (error.response.status === 401) {
          auth.setLogin("", "");
          window.location.href = BASE_URL + "/login.html";
        }
      });
  }

  componentDidMount() {
    if (auth.isLoggedIn()) {
      this.setState({ current_user: auth.user() });
      this.getRepairs();
    } else {
      window.location.href = BASE_URL + "/login.html";
    }
  }

  render() {
    let loaderStyle = this.state.showCompanies
      ? { display: "none" }
      : { display: "block" };
    return (
      <div
        className={
          this.state.showCompanies === true ? "canvas login" : "canvas"
        }
      >
        {this.state.servererror ? <ErrorPopup handleservererror={this.handleserverError} error={this.state.servererror} /> : null}
        {this.state.showCompanies === true ? (
          <>
            <Navbar />
            <Help appGuide="homepage" monitorId={0} />
            <Header currentUser={this.state.current_user} />
            <div className="content">
              <div className="mt-5 container">
                <div className="d-flex justify-content-center align-items-center full-height">
                  <div className="card bg-white">
                    <div className="inner">
                      <div>
                        {" "}
                        <h3 className="text-dark">Select Company</h3>
                        <div className="list-group">
                          {" "}
                          {this.state.companieslist.map((company, ci) => {
                            return (
                              <a
                                className="list-group-item list-group-item-action "
                                key={"c" + ci}
                                onClick={this.selectCompany}
                                data-company-id={company.company_id}
                                data-entity-type={company.entity_type}
                              >
                                {company.company_name}
                              </a>
                            );
                          })}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        {this.state.showData === true ? (
          <>
            <Navbar
              resellerCompanies={this.state.resellerCompanies}
              currentCompany={this.state.currentCompany}
              selectCompany={this.selectCompany}
            />

            <Help appGuide="homepage" monitorId={0} />
            <Header currentUser={this.state.current_user}
              showChangeLog="1"
            />
            <div className="content">
              {/* <ChangeLog /> */}
              <Loadlisting
                dataList={this.state.dataList}
                brands={this.state.brands}
                userRole={this.state.userRole}
                userType={this.state.userType}
                getRepairs={this.getRepairs}
                totalRecords={this.state.totalRecords}
                pageLimit={this.pageLimit}
                totalPages={this.state.totalPages}
                handlePaginationClick={this.handlePaginationClick}
                pageLimitStart={this.state.pageLimitStart}
                warrantyStatus={this.state.warrantyStatus}
                repairStartDate={this.state.repairStartDate}
                repairEndDate={this.state.repairEndDate}
                clearFilters={this.clearFilters}
                companyFilter={this.state.companyFilter}
                getBrand={this.state.getBrand}
                handleCompanySelect={this.handleCompanySelect}
              />
              {/* {this.state.userType != "c" ||
              (this.state.userType == "c" && this.state.userRole == "5") ? (
                <>
                  <div className="bottom-btn-group position-fixed">
                    <span className="icon btn btn-primary p-2 px-3 rounded-circle d-flex justify-content-center align-items-center">
                      <i className="fa fa-plus" aria-hidden="true"></i>
                    </span>
                    <div className="nav-col">
                      <ul className="nav justify-content-end flex-column align-items-end">
                        <li className="nav-item mb-2">
                          <a
                            className="btn btn-primary nav-link rounded-pill"
                            onClick={this.startStandaloneClaim}
                          >
                            New Standalone Claim
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="btn btn-primary nav-link rounded-pill"
                            href="repair-start.html"
                          >
                            Start a new Repair{" "}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )} */}
            </div>
          </>
        ) : (
          <div style={loaderStyle}>
            <Help appGuide="homepage" monitorId={0} />
            <Header currentUser={this.state.current_user} />
            <RepairsLoader />
          </div>
        )}
      </div>
    );
  }
}

const ChangeLog = (props) => {
  return (
    <div className="container-fluid">
      <div className="top-bar rounded-pill">
        <div className="container-fluid">
          <div className="clearfix">
            <div className="float-left">
              <h2>
                <span className="icon">
                  <i className="fa fa-bullhorn" aria-hidden="true"></i>
                </span>{" "}
                Update 2.1 is here. Find out whats new...
              </h2>
            </div>
            <div className="float-right">
              <a href="#" className="btn white-btn">
                Read Changelog
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Loadlisting = (props) => {
  function range(start, end) {
    var ans = [];
    for (let i = start; i <= end; i++) {
      ans.push(i);
    }
    return ans;
  }

  return (
    <>
      <div className="detail management-col">
        <div className="container-fluid">
          {props.getBrand ? (
            <div className="canvas">
              <div className="content">
                <div className="detail">
                  <div className="container-fluid text-center">
                    <div className="repair-form">
                      <h2>Select Brand</h2>
                      <div className="row mt-4">
                        <div className="col-sm-8 offset-sm-2 col-md-6 offset-md-3">
                          <select
                            style={{ minWidth: "50%" }}
                            className="pt-2 pb-2 "
                            onChange={props.handleCompanySelect}
                          >
                            <option value="">------</option>
                            {props.brands
                              ? props.brands.map((v, i) => (
                                <option value={v.short_name} key={i}>
                                  {v.company_name}
                                </option>
                              ))
                              : null}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : props.dataList.length > 0 ? (
            <>
              <div className="fiter-col">
                <form className="align-items-center">
                  <div className="d-flex flex-column mt-4 filter-search">
                    <div className="col-md-12">
                      <div className="row align-items-end justify-content-between">
                        <div className="col-md-12">
                          <div className="row align-items-end">
                            <div className="col-md-2">
                              <div className="d-flex">
                                <label className="mb-2">Warranty Status</label>
                                <a className="ms-1 text-muted">
                                  {/* <i className="far fa-question"></i> */}
                                  <Popover
                                    id='warranty_status'
                                    position='top'
                                    title='Warranty Status'
                                    content='Select the warranty status of product'
                                    type='help'
                                  />
                                </a>
                              </div>
                              <select
                                className="form-control"
                                name="warranty_status"
                                id="warranty_status"
                                defaultValue={props.warrantyStatus}
                              >
                                <option value="" defaultValue="">
                                  -----
                                </option>
                                <option value="Under Warranty">
                                  Under Warranty
                                </option>
                                <option value="Out of Warranty">
                                  Out of Warranty
                                </option>
                              </select>
                            </div>
                            <div className="col-md-2">
                              <div className="d-flex">
                                <label className="mb-2">Repair Number</label>
                                <a className="ms-1 text-muted">
                                  {/* <i className="far fa-question"></i> */}
                                  <Popover
                                    id='repair_number'
                                    position='top'
                                    title='Repair Number'
                                    content='Enter the Repair Number'
                                    type='help'
                                  />
                                </a>
                              </div>
                              <input
                                className="form-control"
                                name="warranty_status"
                                id="repair_number"
                                placeholder="repair number"
                              />

                            </div>
                            {auth.userType()!='c' && <div className="col-md-2">
                              <div className="d-flex">
                                <label className="mb-2">Users</label>
                                <a className="ms-1 text-muted">
                                  {/* <i className="far fa-question"></i> */}
                                  <Popover
                                    id='user_filter'
                                    position='top'
                                    title='User Filter'
                                    content='Select an Option to apply user filter'
                                    type='help'
                                  />
                                </a>
                              </div>
                              <select
                                className="form-control"
                                name="user_filter"
                                id="user_filter"
                                defaultValue="All"
                              >
                                <option value="All">
                                  All
                                </option>
                                <option value="user">
                                {auth.user()?auth.user().detail[0].user_email:"current user"}
                                </option>
                              </select>

                            </div>}
                            {/* {props.brands.length > 0 && (
                        <div className="col-md-4">
                          <label>Brand</label>
                          <select
                            className="form-control"
                            name="brand_selected"
                            id="brand_selected"
                            defaultValue={
                              props.brands.length === 1
                                ? props.brands[0].short_name
                                : props.companyFilter
                            }
                          >
                            {props.brands.length !== 1 ? (
                              <option value="">-----</option>
                            ) : null}
                            {props.brands.length > 0
                              ? props.brands.map((v, i) => (
                                  <option value={`${v.short_name}`} key={i}>
                                    {v.company_name}
                                  </option>
                                ))
                              : null}
                          </select>
                        </div>
                      )}  */}
                            <div className="col-md-4 text-left">
                              <a
                                href="#"
                                className="btn me-2 btn-outline-secondary py-2 px-3 rounded"
                                onClick={props.clearFilters}
                              >
                                Clear Filters
                              </a>
                              <a
                                href="#"
                                className="btn ms-2 btn-outline-primary px-3 py-2 rounded "
                                onClick={props.getRepairs}
                              >
                                Search
                              </a>
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-md-4 d-flex justify-content-end">
                        <Repairclaims />
                      </div> */}
                      </div>
                    </div>
                    <div className="col-md-3 text-left mt-4"></div>
                  </div>
                </form>
              </div>
              <div className="full-details">
                <div className="heading">
                  <span className="text-muted fs-6 repair col-custom-10">Repair #</span>
                  <span className="text-muted fs-6 customer col-custom-15">Customer</span>
                  <span className="text-muted fs-6 serial col-custom-10">Serial No.</span>
                  <span className="text-muted fs-6 product col-custom-20">Product</span>
                  <span className="text-muted fs-6 serial col-custom-10">Reference No.</span>
                  <div className="text-muted fs-6 serial col-custom-15 d-flex flex-column"> <span>Start</span>  <span>(YYYY-MM-DD)</span> </div> 
                  <span className="text-muted fs-6 status col-custom-20 text-right">Warranty Status</span>
                  {/* <span  className="text-muted fs-6 col-custom-15">Repair Type</span> */}
                </div>
                <div className="prduct-list">
                  <RepairsList
                    repairs={props.dataList}
                    userRole={props.userRole}
                    userType={props.userType}
                  />
                </div>

                {/* {props.totalRecords > props.pageLimit ? (
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center mt-4">
                      {Array.apply(null, { length: props.totalPages }).map(
                        (e, i) => (
                          <li
                            className={
                              props.pageLimitStart == i
                                ? "page-item active"
                                : "page-item"
                            }
                            key={i}
                          >
                            <a
                              className="page-link"
                              onClick={
                                props.pageLimitStart != i
                                  ? () => props.handlePaginationClick(Number(i))
                                  : () => { }
                              }
                            >
                              {Number(i + 1)}
                            </a>
                          </li>
                        )
                      )}
                    </ul>
                  </nav>
                ) : (
                  ""
                )} */}
                {/* //<DataPagination nbPageslist={page_numbers} page={page} pagehandler={pagehandler} />
                 */}
                 {/* {console.log(pagenumberslist(10),props.totalPages)} */}
                 <DataPagination nbPageslist={pagenumberslist(props.totalPages)} page={props.pageLimitStart} pagehandler={props.handlePaginationClick} />

              </div>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-between">
                <h2>Repairs To-do</h2>{" "}
                <a
                  className="btn btn-secondary text-white"
                  href="closed-repairs.html"
                >
                  {" "}
                  Completed Repairs
                </a>
              </div>
              <div className="fiter-col">
                <form className="align-items-center">
                  <div className="row align-items-center mt-4 filter-search">
                    <div className="col-md-8">
                      <div className="row align-items-end">
                        <div className="col-md-4">
                          <label className="mb-2">Warranty Status</label>
                          <select
                            className="form-control"
                            name="warranty_status"
                            id="warranty_status"
                          >
                            <option value="" defaultValue="">
                              -----
                            </option>
                            <option value="Under Warranty">Under Warranty</option>
                            <option value="Out of Warranty">
                              Out of Warranty
                            </option>
                          </select>
                        </div>
                        <div className="col-md-2">
                              <div className="d-flex">
                                <label className="mb-2">Repair Number</label>
                                <a className="ms-1 text-muted">
                                  {/* <i className="far fa-question"></i> */}
                                  <Popover
                                    id='repair_number'
                                    position='top'
                                    title='Repair Number'
                                    content='Enter the Repair Number'
                                    type='help'
                                  />
                                </a>
                              </div>
                              <input
                                className="form-control"
                                name="warranty_status"
                                id="repair_number"
                                placeholder="repair number"
                              />

                            </div>
                            {auth.userType()!='c' &&<div className="col-md-2">
                              <div className="d-flex">
                                <label className="mb-2">Users</label>
                                <a className="ms-1 text-muted">
                                  {/* <i className="far fa-question"></i> */}
                                  <Popover
                                    id='user_filter'
                                    position='top'
                                    title='User Filter'
                                    content='Select an Option to apply user filter'
                                    type='help'
                                  />
                                </a>
                              </div>
                              <select
                                className="form-control"
                                name="user_filter"
                                id="user_filter"
                                defaultValue="All"
                              >
                                <option value="All">
                                  All
                                </option>
                                <option value="user">
                                {auth.user()?auth.user().detail[0].user_email:"current user"}
                                </option>
                              </select>

                            </div>}
                        <div className="col-md-4 text-right">
                          <a
                            href="#"
                            className="btn me-2 btn-outline-secondary py-2 px-3 rounded"
                            onClick={props.clearFilters}
                          >
                            Clear Filters
                          </a>
                          <a
                            href="#"
                            className="btn ml-2 btn-outline-primary px-3 py-2 rounded"
                            onClick={props.getRepairs}
                          >
                            Search
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="text-center py-5">
                <h1 className="h2 mb-4">No Ongoing Repair Found</h1>
                <p>click on the "clear filters" to see all repairs</p>
              </div>
            </>
          )}
        </div>
      </div>
      {/* <div className="bottom-btn-group position-fixed ">
      <span className="icon btn btn-primary p-2 px-3 rounded-circle d-flex justify-content-center align-items-center">
        <i className="fal fa-plus" aria-hidden="true"></i>
      </span>
      <div className="nav-col">
						<ul className="nav justify-content-end flex-column align-items-end">
							<li className="nav-item">
                <a className="btn btn-primary nav-link rounded-pill" role="button" data-bs-toggle="popover" data-bs-trigger="focus" title="Start a repair" data-bs-content="To start a repair, Search a serial no.">Start a new Repair</a>
							</li>
						</ul>
					</div> 
    </div> */}

    </>
  );
};

const RepairsList = (props) => {
  return props.repairs.map((repair, i) => (
    <div className="card border-0 mb-2 shadow-sm" key={i}>
      <div className="card-body">
        {props.userRole == "5" || props.userType == "r" || props.userType == "c" ? (
          <a
            href={
              repair.repair_status == "Repaired"
                ? "repair-summary.html?repair_id=" + repair.repair_id
                : auth.userRole()==9?"repair-ro.html?repair_id=" + repair.repair_id:"repair.html?repair_id=" + repair.repair_id
            }
            className="absolute"
          ></a>
        ) : (
          ""
        )}
        <div className="row">
          <div className="col-custom-10">
            <span className="id">
              #{repair.repair_id}
            </span>
            <small className="badge badge-secondary">
              {repair.company_name}
            </small>
          </div>
          <div className="col-custom-15">
            <span className="name">{repair.customer_name}</span>
            <small className="mail">{repair.email}</small>
          </div>
          <div className="col-custom-10">
            <span className="number">{repair.serial}</span>
          </div>

          <div className="col-custom-20">
           
            {repair.external_id == -1 ? <span class="bg-warning px-3 py-2 rounded d-inline-block">STANDALONE</span> : <span className="name">{repair.model_name}</span> }
                          <small className="pro-info">
                            {repair.external_id == -1
                              ? ""
                              : repair.display_name}
                          </small>
          </div>

          <div className="col-custom-10">
            <span className="number">{repair.internal_reference_no}</span>
          </div>

          <div className="col-custom-15 d-flex flex-column">
            <span className="number">{repair.date_created ? DateConversion({option:"1", date1:repair.date_created}).date_1 : ""} </span>
            <span className="number">{repair.date_created ? DateConversion({option:"1", date1:repair.date_created}).time_1 : ""} </span>
          </div>

          <div className="col-custom-20 text-right">
            <span className="">
              <span className="icon">
                <i
                  className={
                    repair.purchase_proof_list_id && !repair.verified && repair.verified != false ? "fa-question-circle fa" :
                      repair.warranty_status === "Under Warranty"
                        ? "fa-check-circle fa"
                        : "fa fa-ban"
                  }
                  aria-hidden="true"
                ></i>
              </span>{" "}
              {repair.purchase_proof_list_id && !repair.verified && repair.verified != false ?
                "Warranty TBD" : repair.warranty_status}{" "}
              {/* {repair.warranty_status} */}
            </span>
            <small>
              {repair.purchase_date != "0000-00-00"
                ? "Warranty began " +
                new Date(repair.purchase_date).toISOString().slice(0, 10)
                : ""}
            </small>
          </div>
          {/* <div className="col-custom-15 text-right">
            <span className="">              
              {repair.warranty_status}
            </span>
            <small>
              {repair.purchase_date != "0000-00-00"
                ? "Repair Started " +
                  new Date(repair.purchase_date).toISOString().slice(0, 10)
                : ""}
            </small>
          </div> */}
        </div>
      </div>
    </div>
  ));
};

ReactDOM.render(<App />, document.getElementById("react-module"));
