import React from "react";

function DataNotFound({message='No Result Found'}) {
  return (
    <div className="text-center py-5">
      <h1 className="h2 mb-4">{message}</h1>
      {/* <p>click on the "clear filters" to see all claims</p> */}
    </div>
  );
}

export default DataNotFound;
