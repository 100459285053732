import React from 'react'
// Helper function to convert Date in frontend to local timezone and displaying them accordingly
function DateConversion(props) {
// console.log(props, "UTC/GMT date and time as props from backend")
let option = props.option // for two types of display option of dates on a page mentioned below
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // to get the local timezone of client surfing the website
// console.log(timezone);
  if(option === "1"){ // Only when there is one date to display on page that is Reapir created date or claim approved or claim rejected etc. example: repair-to do page
    const d1 = new Date(props.date1)
    let date_1 = new Date (d1.toLocaleString("en-US",{dateStyle:"medium", timeZone:timezone})).toISOString().split("T")[0]
    let time_1 = (d1.toLocaleString("en-US",{timeZone:timezone,timeStyle:'short',hourCycle:'h24'}))
    // console.log({date_1 :date_1, time_1 : time_1},  "Date and Time as per local timezone")
    return ({date_1 :date_1, time_1 : time_1 })
  }
  if(option === "2"){ // when we have to display two dates on a page that is Start and completion date example: closed-repair page
    const d1 = new Date(props.date1) // example: d1 = Start date
    let date_1 =new Date (d1.toLocaleString("en-US",{dateStyle:"medium", timeZone:timezone})).toISOString().split("T")[0]
    let time_1 = (d1.toLocaleString("en-US",{timeZone:timezone,timeStyle:'short',hourCycle:'h24'}))
    const d2 = new Date(props.date2) // example: d2 = Completion Date
    let date_2 = new Date (d2.toLocaleString("en-US",{dateStyle:"medium", timeZone:timezone})).toISOString().split("T")[0]
    let time_2 = (d2.toLocaleString("en-US",{timeZone:timezone,timeStyle:'short',hourCycle:'h24'}))
    // console.log({date_1 :date_1, time_1 : time_1, date_2 : date_2, time_2 : time_2  }, "Date and Time as per local timezone")
    return ({date_1 :date_1, time_1 : time_1, date_2 : date_2, time_2 : time_2  }) // returning Start and completion Date
  }
}
export default DateConversion