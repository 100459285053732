import React from 'react'
import DataColumn from './DataColumn'

function DataRow({data,childcomponent,redirecturl}) {
  return (
    <div className='card border-0 mb-2 shadow-sm'>
    <div className="card-body">
      {redirecturl? <a href={redirecturl} className="absolute"></a>:null}
               
                <div className="row">
               {childcomponent}
                </div>
              </div>
              </div>
  )
}

export default DataRow