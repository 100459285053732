import React from "react";

function DataProgressLoader() {
  return (
    <div>
      <div
        id="result"
        className="p-3 d-flex justify-content-center align-items-center"
        style={{ color: "rgb(54, 0, 102)", width: "100%", height: "70vh" }}
      >
        <i className="fa fa-spin fa-spinner" style={{ fontSize: "4em" }} />
      </div>
    </div>
  );
}

export default DataProgressLoader;
