import React from "react";
import { get_objectkeys } from "../utils/object";
import DataRow from "./DataRow";
function DataList({ data, childcomponent, heading }) {
  return (
    <div className="full-details">
      <div class="heading">
        {/* <span class="text-muted fs-6 repair col-custom-10">Repair #</span */}
        {/* > */}
   <div className="card border-0 mb-2  " style={{backgroundColor: 'rgb(234,238,239)', opacity: '0.6'}}>


        <div className="card-body border-0">
        <div className="row">
          {get_objectkeys(heading).map((key) => {
            // let heading_count=get_objectkeys(heading).length
            // let columnlength=heading_count>5?(17-(heading_count-3)):Math.round(100 / get_objectkeys(heading).length)
            // <span className={`text-muted fs-6 repair col-custom-${columnlength}`}>{heading[key]}</span>
            return <div className={`text-muted  col text-wrap`}>
              
              <span className=" d-flex text-muted fs-6">{heading[key]}</span>
              </div>;
          })}
        </div>
        </div>
       
        </div>
      </div>
      <div className="prduct-list">{childcomponent}</div>
    </div>
  );
}

export default DataList;
