const ENV = 5; // 1 = local, 2 = dev , 3 = production, 4 = crc fdw beta, 5 = crc fdw at https://repairs.cs.inc
let BASE_URL = '';
let GOOGLE_CLIENT_ID = '';
let API_URL = '';
let API_USER = '';
let API_PASSWORD = '';
let USER_PIC_PATH = '';
let LABOR_PRICE_PER_HOUR = 0;
let REACT_APP_GEOLOCATION_URL = 'https://geolocation-db.com';
let PAGE_LIMIT = 20;
let STATUS_URL="https://creativesolutionsrepaircenters.statuspage.io/"
const FILE_UPLOAD_SIZE_MB=20
{
    ENV == 1 ?
        (
            BASE_URL = 'http://localhost:8080',
            GOOGLE_CLIENT_ID = '719218831910-2dscle7mg75btepo6mfjagotqknfttko.apps.googleusercontent.com',
            API_URL = 'http://localhost:4001',
            API_USER == 'nodeapiadmin',
            API_PASSWORD = '}Kj7uqQ}M6',
            USER_PIC_PATH = 'http://localhost:4001/static/user_profile/',
            LABOR_PRICE_PER_HOUR = 70,
            PAGE_LIMIT = 20          
        )
        : ''
}
{
    ENV == 2 ?
        (
            BASE_URL = 'https://drc.smallhd.com/crc',
            GOOGLE_CLIENT_ID = '195387339799-ocnhnffcpl3ihj8ke4r0tgoteke8nmq9.apps.googleusercontent.com',
            API_URL = 'https://drc.smallhd.com:4001',
            API_USER = 'nodeapiadmin',
            API_PASSWORD = '}Kj7uqQ}M6',
            USER_PIC_PATH = 'https://drc.smallhd.com:4001/static/user_profile/',
            LABOR_PRICE_PER_HOUR = 70,
            PAGE_LIMIT = 20      
        )
        : ''
}
{
    ENV == 3 ?
        (
            BASE_URL = 'https://crc.smallhd.com',
            GOOGLE_CLIENT_ID = '167465209756-otkn5iamon8kdm698c9fl7pumdpgqtrt.apps.googleusercontent.com',
            API_URL = 'https://crc.smallhd.com:4000',
            API_USER = 'nodeapiadmin',
            API_PASSWORD = '}Kj7uqQ}M6',
            USER_PIC_PATH = 'https://crc.smallhd.com:4000/static/user_profile/',
            LABOR_PRICE_PER_HOUR = 70,
            PAGE_LIMIT = 20           
        )
        :
        ''
}
{
    ENV == 4 ?
        (
            BASE_URL = 'https://fdw.smallhd.com/crc',
            GOOGLE_CLIENT_ID = '983756968561-r24eh14sccrljncsvooe2n0aesd0qe4k.apps.googleusercontent.com', //nwQJgbhaVko6KAIbOD9YmHrX
            API_URL = 'https://fdw.smallhd.com:4001',
            API_USER = 'nodeapiadmin',
            API_PASSWORD = '}Kj7uqQ}M6',
            USER_PIC_PATH = 'https://fdw.smallhd.com:4001/static/user_profile/',
            LABOR_PRICE_PER_HOUR = 70,
            PAGE_LIMIT = 20            
        )
        :
        ''
}
{
    ENV == 5 ?
        (
            BASE_URL = 'https://repairs.cs.inc',
            GOOGLE_CLIENT_ID = '983756968561-r24eh14sccrljncsvooe2n0aesd0qe4k.apps.googleusercontent.com', //nwQJgbhaVko6KAIbOD9YmHrX
            API_URL = 'https://repairs.cs.inc:4001',
            API_USER = 'nodeapiadmin',
            API_PASSWORD = '}Kj7uqQ}M6',
            USER_PIC_PATH = 'https://repairs.cs.inc:4001/static/user_profile/',
            LABOR_PRICE_PER_HOUR = 70,
            PAGE_LIMIT = 20
        )
        :
        ''
}
{
    ENV == 6 ?
        (
            BASE_URL = 'https://repairs.cs.inc/staging',
            GOOGLE_CLIENT_ID = '983756968561-r24eh14sccrljncsvooe2n0aesd0qe4k.apps.googleusercontent.com', //nwQJgbhaVko6KAIbOD9YmHrX
            API_URL = 'https://repairs.cs.inc:5001',
            API_USER = 'nodeapiadmin',
            API_PASSWORD = '}Kj7uqQ}M6',
            USER_PIC_PATH = 'https://repairs.cs.inc:5001/static/user_profile/',
            LABOR_PRICE_PER_HOUR = 70,
            PAGE_LIMIT = 20
        )
        :
        ''
}

export default {FILE_UPLOAD_SIZE_MB,STATUS_URL, BASE_URL, GOOGLE_CLIENT_ID, API_URL, API_USER, API_PASSWORD, USER_PIC_PATH, LABOR_PRICE_PER_HOUR,REACT_APP_GEOLOCATION_URL, PAGE_LIMIT }
