import React from "react";
import { get_objectkeys } from "../utils/object";
function DataColumn({ data }) {
  return (
    <>
    {/* {JSON.stringify(get_objectkeys(data))} */}
      {get_objectkeys(data).map((key) => (
        // <div className={`col-custom-${Math.round(500 / get_objectkeys(data).length)}`}>
        <div className="col">
          <span className="id text-wrap">{data[key]}</span>
          {/* <span className="badge badge-secondary">{data[key]}</span> */}
        </div>
      ))}
    </>
  );
}

export default DataColumn;
