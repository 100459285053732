import * as axios from 'axios';
import appconfig from '../../config';

axios.defaults.withCredentials=true
const API_URL = appconfig.API_URL
const API_USER = appconfig.API_USER
const API_PASSWORD = appconfig.API_PASSWORD


function request(config) {
    return axios(config);
}

function get(url, options) {

    let config = {

        method: 'get',
        url: `${API_URL}/${url}`
    } 

    config = Object.assign(config, options);

    return request(config);

}

function post(url, data, options = {}) {

    let config = {  

        method: 'post',        
        url: `${API_URL}/${url}`,        
        data        
    }

    config = Object.assign(config, options);

    return request(config);

}

function put(url, data, options = {}) {

    let config = {
        method: 'put',
        url: `${API_URL}/${url}`,
        data
    }

    config = Object.assign(config, options);

    return request(config);

}

function _delete(url, options) {

    let config = {
        method: 'delete',
        url: `${API_URL}/${url}`
    }

    config = Object.assign(config, options);

    return request(config);

}

function patch(url, options) {

    let config = {
        method: 'patch',
        url: `${API_URL}/${url}`
    }

    config = Object.assign(config, options);

    return request(config);

}

export default { request, get, post, put, delete: _delete, patch };