import appconfig from "../../../config";
const BASE_URL = appconfig.BASE_URL;
const STATUS_URL=appconfig.STATUS_URL;
import auth from "../../auth";
import http from "../../http";
import queryString, { parse } from "query-string";
import { HeaderLoader } from "../loaders";
import { useLocation } from "react-router-dom";
import {
  headerControl,
  search_index,
} from "../../../rumodules/data/globalsearchdata.json";
import { get_objectkeys } from "../../../rumodules/utils/object";
import { searchcount } from "../../../rumodules/utils/Search";
import { AlgoliaSearcher } from "../../../rumodules/utils/Search";
import React from "react";
import DataList from "../../../rumodules/layout/DataList";
import SearchResultDropDown from "../../../rumodules/pages/SearchResultDropDown";
import ViewPage from "../../../rumodules/pages/ViewPage";
const parsed = queryString.parse(location.search);

const search_by = parsed.q ? parsed.q : parsed.serial;

const helpClick = () => {
  //document.body.classList.toggle("click-help");
  if (document.body.classList.contains("click-help")) {
    document.getElementById("guide-tab").click();
  }
};

const logout = () => {
  const session_id = auth.isLoggedIn();
  const data = new FormData();
  data.append("session_id", session_id);
  http
    .post("user/logout", data, {})
    .then((res) => {
      const response = res.data;
      if (response.data && response.data.logoutUrl) {
        //window.location = response.data.logoutUrl + "&state=" + appconfig.BASE_URL;
        window.location.href = BASE_URL + "/login.html";
      }
    })
    .catch((error) => {});
  auth.setLogin("", "");
  auth.setRole("", "");
  //window.location.href = BASE_URL + "/login.html";
};

export default class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false,
      userRole: auth.userRole(),
      userType: auth.userType(),
      modalVisible1: false,
      notifications: auth.userNotifications(),
      currentCompany: [],
      reload: false,
      latest_notifications: [],
      fetchnotifications: true,
      searchvalue: "",
      searchindex: headerControl,
      recommendedindex: [],
      searchresult_count: {},
      searchresult_data: {},
      current_search_index: "activation_details",
      global_search_loader: false,
    };

    this.openModal = this.openModal.bind(this);
    this.openModal1 = this.openModal1.bind(this);
    this.readNotifications = this.readNotifications.bind(this);
    this.get_latest_notifications = this.get_latest_notifications.bind(this);
    this.handlesearchvalue = this.handlesearchvalue.bind(this);
  }
  async componentDidMount() {
    this.setState({
      searchindex: headerControl,
    });
    await this.getCompanyData();
    if (this.props.showChangeLog && parseInt(this.state.notifications)) {
      this.getHelpArticlesChangeLog();
    }
    this.get_latest_notifications();
  }
  handlesearchvalue = (searchdata) => {
    this.setState({
      searchvalue: searchdata,
      global_search_loader: true,
    });
    if (searchdata.length == 0) {
      this.setState({
        searchindex: headerControl,
      });
    }

    this.setState(
      {
        recommendedindex: [],
      },
      () => {
        let search_index_length=0
        get_objectkeys(search_index).map((indexName) => {
          // AlgoliaSearcher(this.state.searchvalue, indexName,auth.user_Company_or_Reseller_Filter())
          let filters = auth.user_Company_or_Reseller_Filter();
          http
            .get("globalsearch", {
              params: {
                query: this.state.searchvalue,
                indexName: indexName,
                filters: search_index[indexName].skipuser_filter ? "" : filters,
              },
            })
            .then((result) => {
              let hits = result.data.hits;
              let temp_details = this.state.recommendedindex;
              temp_details = [...temp_details, indexName];
              temp_details = [...new Set(temp_details)];
              let temp_searchresult_count = this.state.searchresult_count;
              let temp_searchresult_data = this.state.searchresult_data;
              if (hits.length > 0) {
                
                temp_searchresult_count[indexName] = hits.length;
                temp_searchresult_data[indexName] = hits;
                this.setState({
                  current_search_index:indexName,
                  recommendedindex: temp_details,
                  searchresult_count: temp_searchresult_count,
                  searchresult_data: temp_searchresult_data,
                });
              } else {
                temp_searchresult_count[indexName] = 0;
                temp_searchresult_data[indexName] = [];
                this.setState({
                  searchresult_count: temp_searchresult_count,
                  searchresult_data: temp_searchresult_data,
                });
              }
              let temp_searchindex = headerControl; // to reset search index dropdown
              temp_searchindex = temp_searchindex.filter(
                ({ index }) =>
                  this.state.recommendedindex.includes(index) || index == "q"
              ); //to update recommendation on search
              this.setState({
                searchindex: temp_searchindex,
              });
              
              search_index_length+=1
              // console.log(search_index_length,Object.keys(search_index).length)
              if(search_index_length==Object.keys(search_index).length){
                this.setState({
                  global_search_loader: false
                });
              }
            });
        });
        
      }
    );
  };
  read_notification = (id, ispublic) => {
    let data = new FormData();
    data.append("public", ispublic);
    http
      .put(`notifications/log/${id}`, data)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        this.setState({ servererror: error });
        if (error.response.status === 401) {
          //empty user session
          auth.setLogin("", "");
          window.location.href = BASE_URL + "/login.html";
        }
      });
  };

  get_latest_notifications = () => {
    if (this.state.fetchnotifications) {
      http
        .get("notifications", {
          params: {
            limit: 15,
            type: "unread",
          },
        })
        .then((res) => {
          const respData = res.data;

          this.setState({
            latest_notifications: respData,
            fetchnotifications: false,
          });
        })
        .catch((error) => {
          this.setState({
            fetchnotifications: false,
          });
        });
    }
  };

  getHelpArticlesChangeLog = () => {
    const session_id = auth.isLoggedIn();
    this.setState({
      articles: "",
      showArticle: false,
    });
    http
      .get("help/manuals", {
        params: {
          session_id,
          changeLog: 1,
        },
      })
      .then((res) => {
        const respData = res.data;
        this.setState({
          articles: respData.data,
          showArticle: true,
        });

        this.openModal1();
      })
      .catch((error) => {
        // if(error.response && error.response.status === 401){
        // 	//empty user session
        // 	auth.setLogin('', '')
        // 	window.location.href = BASE_URL+'/login.html';
        // }
      });
  };

  getCompanyData = async () => {
    const session_id = auth.isLoggedIn();

    await http
      .get("user/preferences/", {
        params: {
          session_id,
        },
      })
      .then((res) => {
        // console.log(res.data)
        let currentCompany = res.data.currentCompany
          ? res.data.currentCompany
          : 0;
        auth.setcurrentCompanyId(currentCompany);

        this.setState({
          AllCompanies: res.data.userAllDetails.companies,
          rolesInCompany: res.data.userAllDetails.userCompanyRoles.length
            ? res.data.userAllDetails.userCompanyRoles.sort((a, b) =>
                a.role_name.localeCompare(b.role_name)
              )
            : null,
          resellerCompanies: res.data.userAllDetails.resellers,
          userResellerRoles: res.data.userAllDetails.userResellerRoles.length
            ? res.data.userAllDetails.userResellerRoles.sort((a, b) =>
                a.role_name.localeCompare(b.role_name)
              )
            : null,
          userResellerCompanies: res.data.userAllDetails.userResellerCompanies,
          currentCompanyId: res.data.currentCompany,
        });
      })
      .catch((err) => {
        if (err.response.status == 401) {
          //empty user session
          auth.setLogin("", "");
          window.location.href = BASE_URL + "/login.html";
        } else {
          console.error(err);
        }
      });
  };

  updatePrefs(
    roleId,
    entityType,
    companyId = null,
    reseller_id = 1,
    reload = true
  ) {
    this.setState({ reload: true });
    const company_id = companyId || this.state.currentCompanyId;
    const role_id = roleId;
    const entity_type = entityType;
    const session_id = auth.isLoggedIn();
    // const reseller_id = this.state.resellerCompanies[0].company_id;
    //console.log([company_id, role_id, entity_type, session_id, reseller_id]);
    http
      .post("user/preferences/", {
        company_id,
        role_id,
        entity_type,
        session_id,
        reseller_id,
      })
      .then((res) => {
        const userData = res.data.user;
        const sessionId = res.data.session_id;
        const userID = res.data.user_id;
        auth.setLogin(sessionId, userData, userID, null);
        auth.setcurrentCompanyId(company_id);
        auth.setRole(role_id, entity_type);
        reload ? (window.location.href = BASE_URL) : null;

        //window.location.href= BASE_URL+'/'
      })
      .catch((error) => {
        auth.setLogin("", "");
        if (error.response.status === 404) {
          this.setState({
            inValidCompanyRole: true,
            errorMessage: error.response.data.errorMessage,
          });
        }
      });
  }
  readNotifications = () => {
    this.openModal1();
    const session_id = auth.isLoggedIn();
    const data = new FormData();
    data.append("session_id", session_id);
    http
      .post("user/readnotifications", data, {})
      .then((res) => {
        auth.setNotifications(0);
        this.setState({
          notifications: 0,
        });
      })
      .catch((error) => {});
  };

  openModal = () => {
    const modalVisible = !this.state.modalVisible;
    if (modalVisible) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    this.setState({
      modalVisible,
    });
  };

  openModal1 = () => {
    const modalVisible1 = !this.state.modalVisible1;
    if (modalVisible1) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    this.setState({
      modalVisible1,
    });
  };

  render() {
    let modalStyles = this.state.modalVisible
      ? { display: "block" }
      : { display: "none" };
    let modalStyles1 = this.state.modalVisible1
      ? { display: "block" }
      : { display: "none" };
    let modalClass = this.state.modalVisible ? "show" : "";
    let modalClass1 = this.state.modalVisible1 ? "show" : "";
    return (
      <>
        <header className="pb-0">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center top-heading-bar">
              <div className="d-flex logo-bar">
                <h1 className="cs-logo">
                  <a href={BASE_URL}>
                    <img className="white-logo" src="./images/cs-logo.png" />{" "}
                    <span>Repair Center</span>
                  </a>
                </h1>
                <div className="navbar navbar-expand-lg navbar-dark">
                  <ul className="nav navbar-nav">
                    <li className="nav-item">
                      <a
                        className="nav-link text-success"
                        href={BASE_URL + "/rcs-status.html"}
                      >
                        <i className="fa fa-solid fa-check"></i> System
                        Operational
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="navbar navbar-expand-lg navbar-dark d-lg-inline-block main-nav">
                <ul className="nav navbar-nav">
                  {this.state.userRole == 9 ? null : (
                    <>
                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            window.location.href.replace(BASE_URL, "") ===
                              "/" ||
                            window.location.href.replace(BASE_URL, "") ===
                              "/closed-repairs.html"
                              ? "active"
                              : null
                          }`}
                          href={BASE_URL}
                        >
                          {" "}
                          Repairs
                        </a>
                      </li>

                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            window.location.href.replace(BASE_URL, "") ===
                              "/claims.html" ||
                            window.location.href
                              .replace(BASE_URL, "")
                              .replace("#", "") === "/approval-claims.html" ||
                            window.location.href
                              .replace(BASE_URL, "")
                              .replace("#", "") === "/processed-claims.html" ||
                            window.location.href
                              .replace(BASE_URL, "")
                              .replace("#", "") === "/rejected-claims.html"
                              ? "active"
                              : null
                          }`}
                          href={BASE_URL + "/claims.html"}
                        >
                          Credit Claims
                        </a>
                      </li>

                      {/* <li className="nav-item">
                      <a
                        className={`nav-link ${
                          window.location.href.replace(BASE_URL, "") ===
                            "/credit-memo.html"
                            ? "active"
                            : null
                        }`}
                        href={BASE_URL + "/credit-memo.html"}
                      >
                        Credit-Memo
                      </a>
                    </li> */}

                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            window.location.href.replace(BASE_URL, "") ===
                              "/items-listing.html" ||
                            window.location.href.replace(BASE_URL, "") ===
                              "/user-reseller.html" ||
                            window.location.href.replace(BASE_URL, "") ===
                              "/people.html" ||
                            window.location.href.replace(BASE_URL, "") ===
                              "/products.html" ||
                            window.location.href.replace(BASE_URL, "") ===
                              "/validate.html" ||
                            window.location.href.replace(BASE_URL, "") ===
                              "/brands.html"
                              ? "active"
                              : null
                          }`}
                          href={BASE_URL + "/people.html"}
                        >
                          Setup
                        </a>
                      </li>

                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            window.location.href
                              .replace(BASE_URL, "")
                              .includes("/article.html")
                              ? "active"
                              : null
                          }`}
                          href={BASE_URL + "/article.html"}
                        >
                          Manuals
                        </a>
                      </li>
                      {auth.userType() == "r" && (
                        <li className="nav-item">
                          <a
                            className={`nav-link ${
                              window.location.href.replace(BASE_URL, "") ===
                              "/credit-memo.html"
                                ? "active"
                                : null
                            }`}
                            href={BASE_URL + "/credit-memo.html"}
                          >
                            My Account
                          </a>
                        </li>
                      )}

                    {/* <li className="nav-item">
                        <a
                          className={`nav-link `}
                          href={BASE_URL + "/status"}
                        >
                          Status
                        </a>
                      </li> */}
                      {/* <li className="nav-item"  onClick={()=>document.getElementById('global-search').focus}>
                        <a
                          className="nav-link null"
                          data-bs-toggle="modal"
                          data-bs-target="#searchModal"
                         
                        >
                          <i className="fa fa-search" />
                        </a>
                      </li> */}
                    </>
                  )}

                  {/*<li><a href="#"><i className="fa fa-cart-plus" aria-hidden="true"></i> Order Parts</a></li>
							<li><a href="#"><i className="fa fa-list-ul" aria-hidden="true"></i> credit memos</a></li>
							<li><a href="#"><i className="fa fa-cog" aria-hidden="true"></i> Setting</a></li>*/}
                  {this.props.resellerCompanies ? (
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdownMenuLink"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Companies
                      </a>
                      <div
                        className="dropdown-menu p-0 border-0"
                        aria-labelledby="navbarDropdownMenuLink"
                      >
                        <>
                          {this.props.resellerCompanies.map((company, ci) => {
                            return (
                              <a
                                href="#"
                                className={
                                  company.company_id ==
                                  this.props.currentCompany
                                    ? "list-group-item list-group-item-action active"
                                    : "list-group-item list-group-item-action"
                                }
                                key={"c" + ci}
                                onClick={this.props.selectCompany}
                                data-company-id={company.company_id}
                                data-entity-type={company.entity_type}
                              >
                                {company.company_name}
                              </a>
                            );
                          })}
                        </>
                      </div>
                    </li>
                  ) : (
                    ""
                  )}
                  {/*{this.props.handleWarrantyCliamClick} */}
                  {/* {auth.userType() == "c" ? (
                    <>
                      <li>
                        <a href={BASE_URL + "/closed-repairs.html"}> Claims</a>
                      </li>
                    </>
                  ) : (
                    ""
                  )} */}
                  {/* <li>
                    <a href={BASE_URL + "/warranty-validation.html"}>
                      {" "}
                      Warranty Validation Search
                    </a>
                  </li> */}
                </ul>
              </div>
              <div className="">
                <ul className="nav">
                  {/* <li className="nav-item">
                    <a
                      className="nav-link help-toggle"
                      href={`${BASE_URL}/help.html?email=${this.props.currentUser
                        ? this.props.currentUser.detail[0].user_email
                        : ''}&description='${window.location.href}'`}
                    >
                      Contact Support <i className="far fa-envelope"></i>
                    </a>

                  </li> */}

                  <li className="nav-item">
                    <a
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="nav-link "
                    >
                      <span>
                        Contact Support{" "}
                        <span className="arrow">
                          <img src="images/down-arrow.png" alt="arrow" />
                        </span>
                      </span>
                    </a>
                    <div
                      className="dropdown-menu "
                      style={{ maxWidth: "100px", padding: "5px" }}
                      aria-labelledby="dropdownMenuButton"
                    >
                      <div className="p-2">
                        <a
                          href={`${BASE_URL}/help.html?email=${
                            this.props.currentUser
                              ? this.props.currentUser.detail[0].user_email
                              : ""
                          }&description='${window.location.href}'`}
                        >
                          <i className="far fa-envelope"></i> Submit a Case{" "}
                        </a>
                      </div>
                      <div className="p-2">
                        {/* <a
                          href="https://savvycal.com/cs-crc/15cf7dc4"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="far fa-phone"></i> Schedule a Call{" "}
                        </a> */}
                         <a
                          href="https://scheduler.zoom.us/d/s5dd756d/crc-call-w-lucas-dan"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="far fa-phone"></i> Schedule a Call{" "}
                        </a>
                      </div>
                    </div>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link help-toggle"
                      onClick={() => {
                        if (
                          document.getElementsByTagName("body")[0].className ==
                          "home"
                        ) {
                          document.getElementsByTagName("body")[0].className =
                            "home click-help";
                          helpClick();
                        } else {
                          document.getElementsByTagName("body")[0].className =
                            "home";
                        }
                      }}
                    >
                      {window.location.href.includes("article.html")
                        ? "Help"
                        : "About this Page"}{" "}
                        &nbsp;
                      <i className="fa fa-life-ring" aria-hidden="true"></i>
                    </a>
                  </li>

                  <li className="nav-item">
                  
                    <a
                      id="dropdownMenuButton"
                      className="nav-link "
                      href={STATUS_URL}
                    >
                      <span>
                       Status{" "}&nbsp;
                        <i className="fa fa-desktop" aria-hidden="true"></i>
                      </span>
                    </a>
                    </li>

                 

                  <li className="nav-item notification position-relative">
                    <a
                      // onClick={this.readNotifications}
                      className="nav-link info-link position-relative"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {this.state.latest_notifications &&
                      this.state.latest_notifications.filter((n) => !n.seen)
                        .length > 0 ? (
                        <sup className="notification-info d-flex align-items-center justify-content-center bg-danger text-white rounded-circle position-absolute">
                          {
                            this.state.latest_notifications.filter(
                              (n) => !n.seen
                            ).length
                          }
                        </sup>
                      ) : null}
                      <i className="fa fa-bell-o" aria-hidden="true"></i>
                      {this.state.notifications > 0 ? (
                        <sup className="notification-info d-flex align-items-center justify-content-center bg-danger text-white rounded-circle position-absolute">
                          {this.state.notifications}
                        </sup>
                      ) : (
                        ""
                      )}
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <div className="outer">
                        <ul className="info-list">
                          {this.state.latest_notifications &&
                            this.state.latest_notifications.map((n) => {
                              return (
                                <>
                                  <li className="un-read bg-white position-relative">
                                    <a
                                      href={
                                        n.link
                                          ? n.public
                                            ? n.link
                                            : n.link[0] == "/"
                                            ? BASE_URL + n.link
                                            : BASE_URL + "/" + n.link
                                          : ""
                                      }
                                      onClick={() =>
                                        this.read_notification(n.id, n.public)
                                      }
                                      className="position-absolute overlay-link"
                                    ></a>
                                    <div className="modal-body d-flex justify-content-between align-items-top p-0 border-0 postion-relative">
                                      <div className="col-12">
                                        <h3 className="modal-title fs-6 mb-4 pe-3">
                                          {n.title}{" "}
                                        </h3>
                                        <ul className="d-block p-0 mb-4">
                                          <li className="text-dark">
                                            {n.description}
                                          </li>
                                        </ul>
                                        <div className="d-flex justify-content-between">
                                          <a
                                            className="btn py-0 btn-link text-primary"
                                            // onClick={this.openModal1}
                                            // href={n.link ? n.link : "#"}
                                            // onClick={() =>
                                            //   this.read_notification(
                                            //     n.id,
                                            //     n.public
                                            //   )

                                            // }
                                          >
                                            Learn More
                                          </a>
                                          <span className="m-0 d-block">
                                            <small className="d-block text-muted">
                                              {new Date(
                                                n.created_on
                                              ).toDateString()}
                                            </small>
                                          </span>
                                        </div>
                                      </div>
                                      {n.seen ? null : (
                                        <i
                                          className="fa fa-dot-circle fa-sm text-danger mt-2 position-absolute top-0 end-0"
                                          aria-hidden="true"
                                        />
                                      )}
                                    </div>
                                  </li>
                                </>
                              );
                            })}
                        </ul>
                        <div className="p-4 d-grid gap-2">
                          <a
                            href="notifications.html"
                            className="btn btn-block px-3 py-2 btn-outline-primary"
                          >
                            View All Notifications
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="nav-item account">
                    <a
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="nav-link "
                    >
                      <span>
                        {this.props.currentUser
                          ? this.props.currentUser.detail[0].user_email
                          : null}
                        <span className="arrow">
                          <img src="images/down-arrow.png" alt="arrow" />
                        </span>
                      </span>
                      <small
                        id="current-user-role"
                        className="d-block user-role"
                      >
                        {document.querySelector(".current_user_role")
                          ? (document.getElementById(
                              "current-user-role"
                            ).innerHTML = document
                              .querySelector(".current_user_role")
                              .querySelector(".user_role").innerHTML)
                          : ""}
                      </small>
                    </a>
                    <div
                      className="dropdown-menu dropdown-menu-right"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {!this.state.reload ? (
                        <React.Fragment>
                          <div className="d-flex justify-content-between align-items-center mb-4">
                            <h4 className="m-0">Roles</h4>
                            <ul>
                              <li>
                                {/*<a href="#">View Profile</a>*/}
                                <a
                                  href="#"
                                  onClick={logout}
                                  className="py-2 btn-link text-primary"
                                >
                                  Logout{" "}
                                  <i
                                    className="fa fa-sign-out"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </li>
                              {/*<li><a href="#">Changelog</a></li>*/}
                            </ul>
                          </div>
                          {this.state.rolesInCompany ? (
                            <>
                              <span className="py-2">Brands</span>
                              <ul className="nav d-flex row">
                                {this.state.rolesInCompany.map((v, i) => (
                                  <li
                                    className={
                                      Object.keys(auth.user().companies)
                                        .length > 0
                                        ? auth.user().companies[0]
                                            .company_id === v.company_id &&
                                          auth.userRole() == v.role_id
                                          ? "nav-item active col-6 current_user_role"
                                          : "nav-item col-6"
                                        : "nav-item col-6"
                                    }
                                  >
                                    <a
                                      key={i}
                                      className={`h4 nav-link user_role`}
                                      onClick={() =>
                                        this.updatePrefs(
                                          v.role_id,
                                          v.entity_type,
                                          v.company_id
                                        )
                                      }
                                    >
                                      {
                                        this.state.AllCompanies.filter(
                                          (d) => d.company_id === v.company_id
                                        )[0].company_name
                                      }
                                      {` `}
                                      {v.role_name.split(" ")[0] === "Company"
                                        ? v.role_name.split(" ")[1]
                                        : v.role_name}
                                    </a>
                                    <span className="badge ms-2 rounded-pill bg-dark">
                                      Selected
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            </>
                          ) : null}

                          {this.state.userResellerRoles ? (
                            <>
                              <span className=" mt-3 mb-0">Resellers</span>
                              <ul className="nav d-flex row">
                                {this.state.userResellerRoles.map((v, i) => (
                                  <li
                                    className={
                                      Object.keys(auth.user().resellers)
                                        .length > 0
                                        ? auth.user().resellers[0]
                                            .company_id === v.reseller_id &&
                                          auth.userRole() == v.role_id
                                          ? "nav-item active col-6 current_user_role"
                                          : "nav-item col-6"
                                        : "nav-item col-6"
                                    }
                                  >
                                    {Object.keys(auth.user().resellers).length >
                                    0
                                      ? auth.user().resellers[0].company_id ===
                                          v.reseller_id &&
                                        auth.userRole() == v.role_id &&
                                        localStorage.getItem("hard_refresh") !=
                                          1
                                        ? this.updatePrefs(
                                            v.role_id,
                                            v.entity_type,
                                            this.state.currentCompanyId,
                                            v.reseller_id,
                                            false
                                          )
                                        : null
                                      : null}
                                    {Object.keys(auth.user().resellers).length >
                                    0
                                      ? auth.user().resellers[0].company_id ===
                                          v.reseller_id &&
                                        auth.userRole() == v.role_id
                                        ? localStorage.setItem(
                                            "hard_refresh",
                                            1
                                          )
                                        : null
                                      : null}
                                    <a
                                      key={i}
                                      className="mr-2 user_role"
                                      onClick={() =>
                                        this.updatePrefs(
                                          v.role_id,
                                          v.entity_type,
                                          this.state.currentCompanyId,
                                          v.reseller_id
                                        )
                                      }
                                    >
                                      {this.state.resellerCompanies.filter(
                                        (q) => q.company_id == v.reseller_id
                                      )[0].company_name + " "}
                                      {v.role_name.split(" ")[1]}
                                    </a>
                                    <span className="badge rounded-pill bg-dark ms-2">
                                      Selected
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            </>
                          ) : null}
                          {auth.userType() == "r" &&
                          this.props.userAllDetails &&
                          this.props.userAllDetails.currentResellerRoles &&
                          this.props.userAllDetails.currentResellerRoles
                            .length > 1 ? (
                            <>
                              {/*<div className="dropdown-divider"></div>
											<span className="title">Role</span>
											<ul className="inline">
										{this.props.userAllDetails.currentResellerRoles.map((role, ri) => {
											return (
												<li key={'role'+ri} className={auth.userRole() == role.role_id ? 'active' : '' }><a href="">{role.role_name}</a></li>

											)
										})
									}
										</ul>*/}
                            </>
                          ) : auth.userType() == "c" &&
                            this.props.userAllDetails &&
                            this.props.userAllDetails.currentCompanyRoles &&
                            this.props.userAllDetails.currentCompanyRoles
                              .length > 1 ? (
                            <>
                              <div className="dropdown-divider"></div>
                              <span className="title">Role</span>
                              <ul className="inline">
                                {this.props.userAllDetails.currentCompanyRoles.map(
                                  (role, ri) => {
                                    return (
                                      <li
                                        key={"role" + ri}
                                        className={
                                          auth.userRole() == role.role_id
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        <a href="">{role.role_name}</a>
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            </>
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      ) : (
                        <div>
                          <HeaderLoader />
                        </div>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="clearfix"></div>

            {this.props.showSearch === false ? (
              ""
            ) : (
              <div className="search-col">
                {/* <form
                  className="search pb-3"
                  action={BASE_URL + "/product-details.html"}
                >
                  <input
                    type="search"
                    name="q"
                    placeholder="Search for serial number / product name for non-serialized products.."
                    defaultValue={search_by}
                    onFocus={(e) => e.target.select()}
                    autoFocus
                  />
                </form> */}
                {/* disabled CRC-507 global search */}
                 <div
                  className="search pb-3 d-flex "
                  // action={BASE_URL + "/product-details.html"}
                >
                  <input
                    // autoComplete="off"
                    type="search"
                    name="q"
                    placeholder="Search by serial number, product name, repairs details, or customer details..."
                    defaultValue={
                      search_by ? search_by : this.state.searchvalue
                    }
                    onFocus={(e) => e.target.select()}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        //  this.handlesearchvalue(e.target.value);
                        window.location.replace(
                          `${BASE_URL}/global-search.html?q=${e.target.value}`
                        );
                      }
                      
                      
                    }}
                    onChange={(e)=>{
                      this.setState({
                        searchdata:e.target.value
                      })
                      if(e.target.value==''){
                        this.setState({
                          searchvalue:''
                        })
                      }
                    }}
                    autoFocus
                  />
                  <a
                          href="#"
                          className="btn ms-2 btn btn-outline-light px-3  py-2 rounded"
                          onClick={()=>{
                            window.location.replace(
                              `${BASE_URL}/global-search.html?q=${this.state.searchdata}`
                            );
                          }}
                        >
                          Search

                        </a>
                </div> 
              </div>
            )}
          </div>
        </header>

        <header className="p-0 position-sticky index10 position-relative">
          <div className="container-fluid ">
            <div className="navbar-nav-scroll d-flex justify-content-between align-items-center">
              <ul className="nav subnav me-auto">
                    {this.state.searchvalue?<>
                      <li>
                                      <a
                                        className={`nav-link active `}
                                      >
                                        Global Search
                                      </a>
                                    </li></>:<>
                    {window.location.href.replace(BASE_URL, "").replace("#", "") ===
                  "/article.html" ||
                window.location.href
                  .replace(BASE_URL, "")
                  .replace("#", "")
                  .includes("article.html") ? (
                  <li className="nav-item ">
                    <a
                      className={`nav-link 
                      active`}
                      href={"article.html"}
                    >
                      Manuals
                    </a>
                  </li>
                ) : null}
                {window.location.href.replace(BASE_URL, "").replace("#", "") ===
                "/notifications.html" ? (
                  <li className="nav-item ">
                    <a
                      className={`nav-link 
                      active`}
                      href={"notifications.html"}
                    >
                      Notifications
                    </a>
                  </li>
                ) : null}

                {window.location.href
                  .replace(BASE_URL, "")
                  .replace("#", "")
                  .includes("global-search.html") ? (
                  <>
                  <li className="nav-item ">
                              <a
                                className={`nav-link ${
                                  window.location.href
                                    .replace(BASE_URL, "")
                                    .replace("#", "")
                                    .includes(`global-search.html?q`)
                                    ? "active"
                                    : null
                                }`}
                                href={
                                  BASE_URL +
                                  `/global-search.html?q=` +
                                  parsed[Object.keys(parsed)[0]]
                                }
                              >
                                Global Search
                              </a>
                            </li>
                    {this.state.searchindex.map(({ index, tag }) => {
                      return (
                        <>
                          {index != "q" ? (
                            <li className="nav-item ">
                              <a
                                className={`nav-link ${
                                  window.location.href
                                    .replace(BASE_URL, "")
                                    .replace("#", "")
                                    .includes(`global-search.html?${index}`)
                                    ? "active"
                                    : null
                                }`}
                                href={
                                  BASE_URL +
                                  `/global-search.html?${index}=` +
                                  parsed[Object.keys(parsed)[0]]
                                }
                              >
                                {tag}
                              </a>
                            </li>
                          ) : null}
                        </>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}

                {window.location.href.replace(BASE_URL, "").replace("#", "") ===
                  "/" ||
                window.location.href.replace(BASE_URL, "").replace("#", "") ===
                  "/closed-repairs.html" ? (
                  <>
                    {" "}
                    <li className="nav-item ">
                      <a
                        className={`nav-link ${
                          window.location.href
                            .replace(BASE_URL, "")
                            .replace("#", "") === "/"
                            ? "active"
                            : null
                        }`}
                        href={BASE_URL}
                      >
                        Repairs To-do
                      </a>
                    </li>
                    <li className="nav-item ">
                      <a
                        className={`nav-link ${
                          window.location.href
                            .replace(BASE_URL, "")
                            .replace("#", "") !== "/"
                            ? "active"
                            : null
                        }`}
                        onClick={() => {
                          window.localStorage.setItem("_crc_dealer", "");
                        }}
                        href="closed-repairs.html"
                      >
                        Completed Repairs
                      </a>
                    </li>
                  </>
                ) : (
                  <>
                    {window.location.href
                      .replace(BASE_URL, "")
                      .replace("#", "") === "/claims.html" ||
                    window.location.href
                      .replace(BASE_URL, "")
                      .replace("#", "") === "/approval-claims.html" ||
                    window.location.href
                      .replace(BASE_URL, "")
                      .replace("#", "") === "/claim-errors.html" ||
                    window.location.href
                      .replace(BASE_URL, "")
                      .replace("#", "") === "/processed-claims.html" ||
                    window.location.href
                      .replace(BASE_URL, "")
                      .replace("#", "") === "/rejected-claims.html" ? (
                      <>
                        {" "}
                        <li className="nav-item ">
                          <a
                            className={`nav-link ${
                              window.location.href
                                .replace(BASE_URL, "")
                                .replace("#", "") === "/claims.html"
                                ? "active"
                                : null
                            }`}
                            href={BASE_URL + "/claims.html"}
                          >
                            {this.props.claim_count &&
                            this.props.claim_count != 0 ? (
                              <span className="badge-count me-2">
                                {this.props.claim_count &&
                                this.props.claim_count != 0
                                  ? this.props.claim_count.map((c) => {
                                      if (c.warranty_claim_response == "open") {
                                        return c.count;
                                      }
                                    })
                                  : 0}
                              </span>
                            ) : null}
                            Open Claims
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={`nav-link ${
                              window.location.href
                                .replace(BASE_URL, "")
                                .replace("#", "") === "/approval-claims.html"
                                ? "active"
                                : null
                            }`}
                            href={BASE_URL + "/approval-claims.html"}
                          >
                            {this.props.claim_count &&
                            this.props.claim_count != 0 ? (
                              <span className="badge-count me-2">
                                {this.props.claim_count &&
                                this.props.claim_count != 0
                                  ? this.props.claim_count.map((c) => {
                                      if (
                                        c.warranty_claim_response == "Approved"
                                      ) {
                                        return c.count;
                                      }
                                    })
                                  : 0}
                              </span>
                            ) : null}
                            Approved Claims
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={`nav-link ${
                              window.location.href
                                .replace(BASE_URL, "")
                                .replace("#", "") === "/claim-errors.html"
                                ? "active"
                                : null
                            }`}
                            href={BASE_URL + "/claim-errors.html"}
                          >
                            {this.props.claim_count &&
                            this.props.claim_count != 0 ? (
                              <span className="badge-count me-2">
                                {this.props.claim_count &&
                                this.props.claim_count != 0
                                  ? this.props.claim_count.map((c) => {
                                      if (
                                        c.warranty_claim_response ==
                                        "Processing Error"
                                      ) {
                                        return c.count;
                                      }
                                    })
                                  : 0}
                              </span>
                            ) : null}
                            Claims Error
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={`nav-link ${
                              window.location.href
                                .replace(BASE_URL, "")
                                .replace("#", "") === "/rejected-claims.html"
                                ? "active"
                                : null
                            }`}
                            href={BASE_URL + "/rejected-claims.html"}
                          >
                            {this.props.claim_count &&
                            this.props.claim_count != 0 ? (
                              <span className="badge-count me-2">
                                {this.props.claim_count &&
                                this.props.claim_count != 0
                                  ? this.props.claim_count.map((c) => {
                                      if (
                                        c.warranty_claim_response == "rejected"
                                      ) {
                                        return c.count;
                                      }
                                    })
                                  : 0}
                              </span>
                            ) : null}
                            Rejected Claims
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={`nav-link ${
                              window.location.href
                                .replace(BASE_URL, "")
                                .replace("#", "") === "/processed-claims.html"
                                ? "active"
                                : null
                            }`}
                            href={BASE_URL + "/processed-claims.html"}
                          >
                            {this.props.claim_count &&
                            this.props.claim_count != 0 ? (
                              <span className="badge-count me-2">
                                {this.props.claim_count != 0
                                  ? this.props.claim_count.map((c) => {
                                      if (
                                        c.warranty_claim_response == "Processed"
                                      ) {
                                        return c.count;
                                      }
                                    })
                                  : 0}
                              </span>
                            ) : null}
                            Processed Claims
                          </a>
                        </li>
                      </>
                    ) : (
                      <>
                        {window.location.href.replace(BASE_URL, "") ===
                        "/index.html" ? (
                          <>
                            {" "}
                            <>
                              {" "}
                              <li className="nav-item ">
                                <a
                                  className={`nav-link ${
                                    window.location.href.replace(
                                      BASE_URL,
                                      ""
                                    ) === "/items-listing.html"
                                      ? "active"
                                      : null
                                  }`}
                                  href={BASE_URL + "/items-listing.html"}
                                >
                                  {/* Items */}
                                </a>
                              </li>
                              <li>
                                <a
                                  className={`nav-link ${
                                    window.location.href.replace(
                                      BASE_URL,
                                      ""
                                    ) === "/user-reseller.html"
                                      ? "active"
                                      : null
                                  }`}
                                  href={BASE_URL + "/user-reseller.html"}
                                >
                                  {/* Users */}
                                </a>
                              </li>
                            </>
                          </>
                        ) : (
                          <>
                            {window.location.href.replace(BASE_URL, "") ===
                              "/items-listing.html" ||
                            window.location.href.replace(BASE_URL, "") ===
                              "/validate.html" ||
                            window.location.href
                              .replace(BASE_URL, "")
                              .replace("#", "") === "/products.html" ||
                            window.location.href
                              .replace(BASE_URL, "")
                              .replace("#", "") === "/people.html" ||
                            window.location.href.replace(BASE_URL, "") ===
                              "/brands.html" ? (
                              <>
                                <li className="nav-item ">
                                  <a
                                    className={`nav-link ${
                                      window.location.href
                                        .replace(BASE_URL, "")
                                        .replace("#", "") === "/people.html"
                                        ? "active"
                                        : null
                                    }`}
                                    // onClick={() => {
                                    //   window.localStorage.setItem("", "");
                                    // }}
                                    href={BASE_URL + "/people.html"}
                                  >
                                    People
                                  </a>
                                </li>

                                {this.state.userType == "c" ? (
                                  <>
                                    <li className="nav-item ">
                                      <a
                                        className={`nav-link ${
                                          window.location.href
                                            .replace(BASE_URL, "")
                                            .replace("#", "") === "/brands.html"
                                            ? "active"
                                            : null
                                        }`}
                                        // onClick={() => {
                                        //   window.localStorage.setItem("", "");
                                        // }}
                                        href={BASE_URL + "/brands.html"}
                                      >
                                        Brands
                                      </a>
                                    </li>
                                    <li className="nav-item ">
                                      <a
                                        className={`nav-link ${
                                          window.location.href
                                            .replace(BASE_URL, "")
                                            .replace("#", "") ===
                                          "/products.html"
                                            ? "active"
                                            : null
                                        }`}
                                        href={BASE_URL + "/products.html"}
                                      >
                                        Product
                                      </a>
                                    </li>
                                    <li className="nav-item ">
                                      <a
                                        className={`nav-link ${
                                          window.location.href
                                            .replace(BASE_URL, "")
                                            .replace("#", "") ===
                                          "/validate.html"
                                            ? "active"
                                            : null
                                        }`}
                                        href={BASE_URL + "/validate.html"}
                                      >
                                        Validate
                                      </a>
                                    </li>

                                    <li className="nav-item ">
                                      <a
                                        className={`nav-link ${
                                          window.location.href.replace(
                                            BASE_URL,
                                            ""
                                          ) === "/items-listing.html"
                                            ? "active"
                                            : null
                                        }`}
                                        href={BASE_URL + "/items-listing.html"}
                                      >
                                        {window.location.href.replace(
                                          BASE_URL,
                                          ""
                                        ) === "/repair-summary.html"
                                          ? " "
                                          : "  Items"}
                                      </a>
                                    </li>
                                  </>
                                ) : null}
                              </>
                            ) : (window.location.href
                                .replace(BASE_URL, "")
                                .includes("product-details.html") ||
                                window.location.href
                                  .replace(BASE_URL, "")
                                  .includes("repairs-log.html") ||
                                window.location.href
                                  .replace(BASE_URL, "")
                                  .includes("claims-log.html") ||
                                window.location.href
                                  .replace(BASE_URL, "")
                                  .includes("warranty-registration.html") ||
                                window.location.href
                                  .replace(BASE_URL, "")
                                  .includes("shipments.html") ||
                                window.location.href
                                  .replace(BASE_URL, "")
                                  .includes("credit-memo-log.html")) &&
                              auth.userType() == "c" ? (
                              <>
                                <li>
                                  <a
                                    className={`nav-link ${
                                      window.location.href
                                        .replace(BASE_URL, "")
                                        .includes("product-details.html")
                                        ? "active"
                                        : null
                                    }`}
                                    href={
                                      BASE_URL +
                                      "/product-details.html" +
                                      `?q=${this.props.serial}${
                                        this.props.item_id
                                          ? `&item_id=${this.props.item_id}`
                                          : ""
                                      }`
                                    }
                                  >
                                    Warranty
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className={`nav-link ${
                                      window.location.href
                                        .replace(BASE_URL, "")
                                        .includes("shipments.html")
                                        ? "active"
                                        : null
                                    }`}
                                    href={
                                      BASE_URL +
                                      "/shipments.html" +
                                      `?serial=${this.props.serial}${
                                        this.props.item_id
                                          ? `&item_id=${this.props.item_id}`
                                          : ""
                                      }`
                                    }
                                  >
                                    Shipments
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className={`nav-link ${
                                      window.location.href
                                        .replace(BASE_URL, "")
                                        .includes("warranty-registration.html")
                                        ? "active"
                                        : null
                                    }`}
                                    href={
                                      BASE_URL +
                                      "/warranty-registration.html" +
                                      `?serial=${this.props.serial}${
                                        this.props.item_id
                                          ? `&item_id=${this.props.item_id}`
                                          : ""
                                      }`
                                    }
                                  >
                                    Registrations
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className={`nav-link ${
                                      window.location.href
                                        .replace(BASE_URL, "")
                                        .includes("repairs-log.html")
                                        ? "active"
                                        : null
                                    }`}
                                    href={
                                      BASE_URL +
                                      "/repairs-log.html" +
                                      `?serial=${this.props.serial}${
                                        this.props.item_id
                                          ? `&item_id=${this.props.item_id}`
                                          : ""
                                      }`
                                    }
                                  >
                                    Repairs
                                  </a>
                                </li>
                                {this.state.userRole == 9 ? null : (
                                  <>
                                    <li>
                                      <a
                                        className={`nav-link ${
                                          window.location.href
                                            .replace(BASE_URL, "")
                                            .includes("claims-log.html")
                                            ? "active"
                                            : null
                                        }`}
                                        href={
                                          BASE_URL +
                                          "/claims-log.html" +
                                          `?serial=${this.props.serial}${
                                            this.props.item_id
                                              ? `&item_id=${this.props.item_id}`
                                              : ""
                                          }`
                                        }
                                      >
                                        Claims
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className={`nav-link ${
                                          window.location.href
                                            .replace(BASE_URL, "")
                                            .includes("credit-memo-log.html")
                                            ? "active"
                                            : null
                                        }`}
                                        href={
                                          BASE_URL +
                                          "/credit-memo-log.html" +
                                          `?serial=${this.props.serial}${
                                            this.props.item_id
                                              ? `&item_id=${this.props.item_id}`
                                              : ""
                                          }`
                                        }
                                      >
                                        Credit Memo
                                      </a>
                                    </li>
                                  </>
                                )}
                              </>
                            ) : null}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}</>}
              </ul>
            </div>
          </div>
        </header>

        <div
          className={`modal fade in ` + modalClass}
          style={modalStyles}
          tabIndex="-1"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered crc-modal"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">CRC v1.2.5</h5>
              </div>
              <div className="modal-body ">
                <strong>What's New:</strong>
                <p>There are some updates and bug fixes.</p>
                <ul className="text-list">
                  <li>Processed Credit Memo details in closed repair list.</li>
                  <li>Minor bug fixes.</li>
                </ul>
                <p className="text-dark"></p>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-primary px-4"
                  onClick={this.openModal}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`modal-backdrop fade ` + modalClass}
          style={modalStyles}
        ></div>

        <div
          className={`modal fade in ` + modalClass1}
          style={modalStyles1}
          tabIndex="-1"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered crc-modal"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title m-0">CRC Changelog</h2>
              </div>
              <div className="modal-body ">
                {this.state.showArticle ? (
                  <>
                    {this.state.articles && this.state.articles.length ? (
                      <div className="">
                        <div className="" id="accordionExample">
                          {this.state.articles.map((chapterData, i) =>
                            this.state.helpType === 3 ? (
                              chapterData ? (
                                <div className="card" key={i}>
                                  <div className="card-body px-0">
                                    <h3>{chapterData.article.title}</h3>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: chapterData.article.html_body,
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : (
                                ""
                              )
                            ) : (
                              <div className="card border-0" key={i}>
                                <div
                                  className="card-header bg-transparent px-0 mb-3"
                                  id="heading3"
                                >
                                  <h2 className="mb-0">
                                    <button
                                      className="btn px-0 text-dark w-100 btn-link btn-block text-left d-flex justify-content-between align-items-center"
                                      type="button"
                                    >
                                      {this.state.helpType == 1 ? (
                                        <>
                                          {chapterData.title.split("__").pop()}
                                        </>
                                      ) : (
                                        chapterData.title
                                      )}
                                    </button>
                                  </h2>
                                </div>
                                <div id={"collapse" + i} className="">
                                  <div className="card-body px-0">
                                    {chapterData.articleData &&
                                    chapterData.articleData.length ? (
                                      <>
                                        {chapterData.articleData.map(
                                          (articleContent, a) => (
                                            <>
                                              {articleContent.article &&
                                              articleContent.article
                                                .html_body &&
                                              articleContent.article
                                                .html_body != "\n" ? (
                                                <>
                                                  <h3>
                                                    {
                                                      articleContent.article
                                                        .title
                                                    }
                                                  </h3>
                                                  <div
                                                    className="mt-3 mb-4"
                                                    key={a}
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        articleContent.article
                                                          .html_body,
                                                    }}
                                                  />
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          )
                                        )}
                                      </>
                                    ) : (
                                      <p>No content found</p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="card">
                        <div className="card-body">
                          <h3 className="">No data found</h3>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className=" my-4">
                    <div className="">
                      <h2 className="loading">
                        {"\u00A0"}
                        <span className="line"></span>
                      </h2>
                      <p className="postion-relative loading">
                        test
                        <span className="line"></span>
                      </p>
                      <p className="loading">
                        test
                        <span className="line"></span>
                      </p>
                      <p className="loading">
                        test
                        <span className="line"></span>
                      </p>
                      <div className="video loading">
                        <span className="line"></span>
                        <iframe src=""></iframe>
                      </div>
                    </div>
                  </div>
                )}

                {/* <strong>What's New:</strong>
                <ul className="text-list">
                  <li>
                    Added ability to reverse Repair/Replace action by the
                    component item.
                  </li>
                </ul> */}
                <p className="text-dark"></p>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-primary px-4"
                  // onClick={this.openModal1}
                  data-bs-dismiss="modal"
                  onClick={this.readNotifications}
                >
                  Acknowledge
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`modal-backdrop fade ` + modalClass1}
          style={modalStyles1}
        ></div>

        {this.state.searchvalue ? (
          <>
            <div
              className=" dropdown-menu show content"
              style={{ height: "150%", zIndex: 997 }}
            >
              <div className="detail">
                <div className="container-fluid">
                  <div className="row align-items-end">
                    <div className="col-md-12">
                      {!this.state.global_search_loader?<div className="card">
                        <ul
                          className="nav d-flex row"
                          style={{display:"inline-block" }}
                        >
                          <li className="nav-item col-3">
                            <div
                              classname="searchResults  container-fluid"
                              style={{
                                width: "100%",
                                maxHeight: "1000px",
                                minheight: "500px",
                                // overflowY: "auto",
                              }}
                            >
                              <label className="p-2 searchLabel">
                                Search "{this.state.searchvalue}" in
                              </label>
                             
                                <ul
                                className=" list-unstyled m-0 w-100"
                                aria-labelledby="dropdownMenuButton1"
                                id="selectitem"
                              >
                                {this.state.searchindex.map(
                                  ({ index, tag, url }) => {

                                    return (
                                      <>
                                        <li
                                          className="col-6 p-2 w-100"
                                          onClick={() => {
                                            if (index != "q") {
                                              this.setState({
                                                current_search_index: index,
                                              });
                                            } else if (index == "q") {
                                              window.location.replace(
                                                `${BASE_URL}${url}${index}=${this.state.searchvalue}`
                                              );
                                            }
                                          }}
                                        >
                                          <a className="p-2 listSearch d-flex justify-content-between align-items-center">
                                            <span className="">{tag}</span>
                                            {this.state.searchresult_count[
                                              index
                                            ] ? (
                                              <span className="searchNumb">
                                                {this.state.searchresult_count[
                                                  index
                                                ] < 20
                                                  ? this.state
                                                      .searchresult_count[index]
                                                  : `${this.state.searchresult_count[index]}+ `}{" "}
                                                results
                                              </span>
                                            ) : (
                                              <span className="searchNumb">
                                                {index != "q"
                                                  ? `0 results`
                                                  : ""}
                                              </span>
                                            )}
                                          </a>
                                        </li>
                                      </>
                                    );
                                  }
                                )}
                                
                              </ul>
                            </div>
                          </li>
                          <li className="nav-item col-9" style={{
                                borderLeft: "1px solid rgba(0,0,0,.125)",
                                // width: "100%",
                                // maxHeight: "1000px",
                                // minheight: "500px",
                                // overflowY: "auto",
                              }}
                              >
                            
                              <label className="p-2 searchLabel">
                                {
                                  search_index[this.state.current_search_index]
                                    .tag
                                }
                              </label>
                              {
                                <div
                                  style={{
                                    overflowY: "auto",
                                    // overflowX: "auto",
                                    // height: 250,
                                  }}
                                >
                                  <SearchResultDropDown
                                    data={
                                      this.state.searchresult_data[
                                        this.state.current_search_index
                                      ]
                                    }
                                    indexName={this.state.current_search_index}
                                    searchvalue={this.state.searchvalue}
                                  />
                                </div>
                              }
                         
                          </li>
                        </ul>
                      </div>
                      : <div>
                      <div
                        id="result"
                        className="p-3 d-flex justify-content-center align-items-center"
                        style={{
                          color: "#360066",
                          width: "100%",
                          height: "70vh",
                        }}
                      >
                     
                         
                        <i
                          className="fa fa-spin fa-spinner"
                          style={{ fontSize: "4em" }}
                        ></i>
                         {/* <p  style={{ fontSize: "4em" }}>Searching...</p> */}
                        
                      </div>
                    </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}
