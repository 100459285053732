import React from "react";
import http from "../../http";
import appconfig from "../../../config";
const BASE_URL = appconfig.BASE_URL;
export default function ErrorPopup(props) {
  return (
    <>
    {/* {console.log(props.error)} */}
      {props.error && (props.error.message!='Request failed with status code 401') ? (
        <div
          id="exampleModalCenter"
          className="modal fade show "
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          style={{ paddingRight: 15, display: "block",zIndex:10000 }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header bg-warning d-flex justify-content-between">
                <h2 className="modal-title" id="exampleModalCenterTitle">
                  {JSON.stringify(props.error, null, '\t').includes('Request failed with status code 408') ? 'Server is taking too long to respond' : ' Something Went Wrong'}
                </h2>
                <button
                  type="button"
                  onClick={() => {
                    props.handleservererror("close");
                  }}
                  className="btn"
                  data-dismiss="modal"
                >
                   <i className="fa fa-close modal-title"></i>
                </button> 
               

              </div>
              <div className="modal-body text-center">
                <p>
                  {JSON.stringify(props.error, null, '\t').includes('Request failed with status code 408') ? 'It seems our servers are not responding in time. This could be due to increased traffic or missing data that you are looking for. You can choose for the page to load or send an error report.' :

                    props.error.response
                      ? props.error.response.data.msg || props.error.response.data.message
                        ? props.error.response.data.msg || props.error.response.data.message
                        : props.error.response.data.error
                          ? props.error.response.data.error || props.error.status
                          :props.error.message ? props.error.message : props.error.msg ? props.error.msg
                            : "An unknown error has occurred. Please try again. If this keeps happening, click the report button below to send an error report to technical support.":'An unknown error has occurred. Please try again. If this keeps happening, click the report button below to send an error report to technical support.'}
                </p>
                {
                  props.error && (props.error.message.includes('500')) &&
                  <small>Something went wrong on our end. Please try again later.</small>
                }
                
              </div>
              <div className="modal-footer">
                {/* <button
                  type="button"
                  onClick={() => {
                    props.handleservererror("close");
                  }}
                  className="btn btn-outline-dark"
                  data-dismiss="modal"
                >
                  Wait
                </button> */}
                <button
                  type="button"
                  onClick={() => {
                    props.handleservererror("close");
                    window.location.reload();
                  }}
                  className="btn btn-outline-dark"
                  data-dismiss="modal"
                >
                   Try Again
                </button>
                <button
                  type="button"
                  onClick={() => {
                    let e = props.error;
                    // console.log(JSON.stringify(e, null, '\t'))
                    http
                      .post("report/", {
                        errorlog: e,
                      })
                      .then((res) => {
                        if (res.status === 200) {
                          alert("report sent successfully");
                          props.handleservererror("close");
                          window.location.replace(BASE_URL);
                        }
                      })
                      .catch((error) => {
                        alert("something went wrong try again/contact support");
                        props.handleservererror("close");
                      });
                  }}
                  className="btn text-white bg-warning"
                >
                  Report
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
