import React from "react";
import appconfig from "../../../config";
import auth from "../../auth";

export class RepairsLoader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var userNumber = 5;
    return (
      <div className="content">
        <div className="detail">
          <div className="container-fluid">
            <div className="fiter-col">
              <form className="align-items-center">
                <div className="d-flex flex-column mt-4 filter-search">
                  <div className="col-md-12">
                    <div className="row align-items-end justify-content-between">
                      <div className="col-md-8">
                        <div className="row align-items-end">
                          <div className="col-md-4">
                            <label className="mb-2">Warranty Status</label>
                            <select
                              className="form-control disabled"
                              name="warranty_status"
                              id="warranty_status"
                              disabled
                            >
                              <option value="" selected="">
                                -----
                              </option>
                              <option value="Under Warranty">
                                Under Warranty
                              </option>
                              <option value="Out of Warranty">
                                Out of Warranty
                              </option>
                            </select>
                          </div>
                          <div className="col-md-4">
                            <a
                              href="#"
                              className="btn ml-2 btn-outline-primary px-3 py-2 rounded disabled"
                            >
                              Search
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 d-flex justify-content-end">
                        <div className="d-flex-col align-content-end">
                          <span className="btn ml-3 py-2 btn-outline-danger rounded disabled">
                            Get Repair Claims
                          </span>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 text-left mt-4"></div>
                </div>
              </form>
            </div>

            <div className="full-details">
              <form method="post" action="#">
                <div className="heading">
                  <span className="repair col-custom-10 text-muted fs-6">
                    Repair #
                  </span>
                  <span className="customer col-custom-15 text-muted fs-6">
                    Customer
                  </span>
                  <span className="serial col-custom-10 text-muted fs-6">
                    Serial No.
                  </span>
                  <span className="product col-custom-20 text-muted fs-6">
                    Product
                  </span>
                  <span className="serial col-custom-10 text-muted fs-6">
                    Reference No.
                  </span>
                  <span className="serial col-custom-15 text-muted fs-6">
                    Start
                  </span>
                  <span className="status col-custom-20 text-muted text-right">
                    Warranty Status
                  </span>
                  {/* <span className="col-custom-15 text-muted fs-6">Repair Type</span> */}
                </div>
                {Array.apply(null, { length: userNumber }).map((e, i) => (
                  <div className="prduct-list" key={i}>
                    <div className="card border-0 mb-2 shadow-sm">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-custom-10 ">
                            <div className="loading h-100">
                              <span className="big-line"></span>
                              <span className="line"></span>
                            </div>
                          </div>
                          <div className="col-custom-15 ">
                            <div className="loading h-100">
                              <span className="big-line"></span>
                              <span className="line"></span>
                            </div>
                          </div>
                          <div className="col-custom-10 ">
                            <div className="mail loading h-100">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className="big-line"></span>
                            </div>
                          </div>
                          <div className="col-custom-20 ">
                            <div className="loading h-100">
                              <span className="big-line"></span>
                              <span className="line"></span>
                            </div>{" "}
                          </div>
                          <div className="col-custom-10 ">
                            <div className="number loading h-100">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className=" big-line"></span>
                            </div>
                          </div>
                          <div className="col-custom-15 ">
                            <div className="number loading h-100">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className=" big-line"></span>
                            </div>
                          </div>
                          <div className="col-custom-20 ">
                            <div className="number loading h-100">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className=" big-line"></span>
                            </div>
                          </div>
                          {/* <div className="col-custom-15">
                            <div className="loading h-100">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className=" big-line"></span>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RepairsLoader;

export class RepairDetailLoader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const loaderRepeat = 5;
    return (
      <div className="content">
        <div className="top-bar position-static card light-gray-bg py-4">
          <div className="container-fluid">
            <div className="prduct-list">
              <div className="card border-0 bg-transparent">
                <div className="row">
                  <div className="col-custom-15">
                    <div className="">
                      <span className="id loading">
                        <span className="line"></span>
                        &nbsp
                      </span>
                      <small className="badge badge-secondary loading">
                        <span className="line"></span>
                        &nbsp
                      </small>
                    </div>
                  </div>
                  <div className="col-custom-15">
                    <span className="name loading">
                      <span className="line"></span>
                      &nbsp
                    </span>
                    <small className="mail loading">
                      <span className="line"></span>
                      &nbsp
                    </small>
                  </div>
                  <div className="col-custom-15">
                    <span className="name loading">
                      <span className="line"></span>
                      &nbsp
                    </span>
                    <small className="pro-info loading">
                      <span className="line"></span>
                      &nbsp
                    </small>
                  </div>
                  <div className="col-custom-15">
                    <span className="number loading">
                      <span className="line"></span>
                      &nbsp
                    </span>
                  </div>
                  <div className="col-custom-15">
                    <span className="text-danger loading">
                      <span className="line"></span>
                      <span className="icon text-danger">
                        <i className="fa fa-ban" aria-hidden="true"></i>
                      </span>{" "}
                      Under Warranty
                    </span>
                    <small className="loading">
                      <span className="line"></span>
                      &nbsp
                    </small>
                  </div>
                  <div className="col-custom-20">
                    <span className="text-danger loading">
                      <span className="line"></span>
                      <span className="icon text-danger">
                        <i className="fa fa-ban" aria-hidden="true"></i>
                      </span>{" "}
                      Under Warranty
                    </span>
                    <small className="loading">
                      {" "}
                      <span className="line"></span>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="detail">
          <div className="container-fluid">
            <div className="d-flex justify-content-between">
              <h2>
                <a href="/" className="txt-purple">
                  <i
                    className="fal fa-chevron-left me-2"
                    aria-hidden="true"
                  ></i>
                </a>{" "}
                Repair Summary
              </h2>
            </div>

            <div className="full-details">
              <div className="tab-col">
                <div className="card">
                  <div className="card-header bg-transparent py-0">
                    <ul
                      className="nav nav-tabs border-0"
                      id="myTab"
                      role="tablist"
                    >
                      <li className="nav-item border-end" role="presentation">
                        <button
                          className="nav-link border-0 py-4  h3 active  text-dark"
                          id="home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#repair"
                          type="button"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                        >
                          Repair Detail
                        </button>
                      </li>
                      <li className="nav-item border-end" role="presentation">
                        <button
                          className="nav-link border-0 p-4 h3 text-dark "
                          id="home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#repair"
                          type="button"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                        >
                          Claim Details
                        </button>
                      </li>
                      <li
                        className="nav-item border-end d-none"
                        role="presentation"
                      >
                        <a
                          className="nav-link border-0 py-4  text-dark  h3"
                          href="http://localhost:8080/claim-detail.html"
                        >
                          Claim Detail
                        </a>
                      </li>
                      <li
                        className="nav-item border-start ms-auto"
                        role="presentation"
                      >
                        <button
                          className="nav-link border-0 py-4 text-dark h3"
                          id="profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#chat"
                          type="button"
                          role="tab"
                          aria-controls="profile"
                          aria-selected="false"
                        >
                          Chat
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content card-body">
                    <div className="tab-pane fade active show">
                      <div className="card border-0">
                        <div className="card-header  border-0 bg-transparent pt-3 pb-0">
                          <div className="row">
                            <div className="col-6">
                              <span className="mb-2 text-muted">
                                Customer Comments
                              </span>
                            </div>
                            <div className="col-6">
                              <span className="mb-2 text-muted">
                                Claim Status
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="card-body py-4">
                          <div className="row">
                            <div className="col-6">
                              <div className="loading">
                                <span className="line"></span>
                                <span className="un-bold">&nbsp</span>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="loading">
                                <span className="line"></span>
                                <span className="un-bold d-inline-block ml-2">
                                  rejected
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <form method="post" action="#">
                        <div className="heading border-bottom mb-3 pb-3">
                          <span className="repair col-custom-15 text-muted fs-6">
                            Component Name
                          </span>
                          <span className="product col-custom-12 text-muted fs-6">
                            Fail Type
                          </span>
                          <span className="customer col-custom-12 text-muted fs-6">
                            Fail Reason
                          </span>
                          <span className="customer col-custom-12 text-muted fs-6">
                            Proof
                          </span>
                          <span className="customer col-custom-12 text-muted fs-6">
                            Fail Notes
                          </span>
                          <span className="customer col-custom-10 text-muted fs-6">
                            Repair Notes
                          </span>
                          <span className="serial col-custom-15 text-muted fs-6">
                            Action
                          </span>
                          <span className="product col-custom-12 text-muted fs-6">
                            Quantity
                          </span>
                        </div>

                        <div className="prduct-list">
                          <div className="card border-0 mb-2 ">
                            <div className="card-body">
                              {Array.apply(null, { length: loaderRepeat }).map(
                                (e, i) => (
                                  <>
                                    <div className="row" key={i}>
                                      <div className="col-custom-20">
                                        <div className="loading">
                                          <span className="line"></span>
                                          <small className="">&nbsp</small>
                                        </div>
                                      </div>
                                      <div className="col-custom-10">
                                        <div className="loading">
                                          <span className="line"></span>
                                          <small className="mail ">&nbsp</small>
                                        </div>
                                      </div>
                                      <div className="col-custom-15">
                                        <div className="loading">
                                          <span className="line"></span>
                                          <small className="mail ">&nbsp</small>
                                        </div>
                                      </div>

                                      <div className="col-custom-15">
                                        <div className="loading">
                                          <span className="line"></span>
                                          <small className="number ">
                                            &nbsp
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-custom-15">
                                        <div className="loading">
                                          <span className="line"></span>
                                          <small className="number">
                                            &nbsp
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-custom-15">
                                        <div className="loading">
                                          <span className="line"></span>
                                          <small className="number">
                                            &nbsp
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-custom-10">
                                        <div className="loading">
                                          <span className="line"></span>
                                          <small className="number">
                                            &nbsp
                                          </small>
                                        </div>
                                      </div>
                                    </div>
                                    <hr />
                                  </>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class RepairSummaryLoader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const loaderRepeat = 5;
    return (
      <div className="content">
        <div className="top-bar position-static card light-gray-bg py-4">
          <div className="container-fluid">
            <div className="prduct-list">
              <div className="card border-0 bg-transparent">
                <div className="row">
                  <div className="col-custom-15">
                    <div className="">
                      <span className="id loading">
                        <span className="line"></span>
                        &nbsp
                      </span>
                      <small className="badge badge-secondary loading">
                        <span className="line"></span>
                        &nbsp
                      </small>
                    </div>
                  </div>
                  <div className="col-custom-15">
                    <span className="name loading">
                      <span className="line"></span>
                      &nbsp
                    </span>
                    <small className="mail loading">
                      <span className="line"></span>
                      &nbsp
                    </small>
                  </div>
                  <div className="col-custom-15">
                    <span className="name loading">
                      <span className="line"></span>
                      &nbsp
                    </span>
                    <small className="pro-info loading">
                      <span className="line"></span>
                      &nbsp
                    </small>
                  </div>
                  <div className="col-custom-15">
                    <span className="number loading">
                      <span className="line"></span>
                      &nbsp
                    </span>
                  </div>
                  <div className="col-custom-15">
                    <span className="text-danger loading">
                      <span className="line"></span>
                      <span className="icon text-danger">
                        <i className="fa fa-ban" aria-hidden="true"></i>
                      </span>{" "}
                      Under Warranty
                    </span>
                    <small className="loading">
                      <span className="line"></span>
                      &nbsp
                    </small>
                  </div>
                  <div className="col-custom-20">
                    <span className="text-danger loading">
                      <span className="line"></span>
                      <span className="icon text-danger">
                        <i className="fa fa-ban" aria-hidden="true"></i>
                      </span>{" "}
                      Under Warranty
                    </span>
                    <small className="loading">
                      {" "}
                      <span className="line"></span>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="detail">
          <div className="container-fluid">
            <div className="d-flex justify-content-between">
              <h2>
                <a href="/" className="txt-purple">
                  <i
                    className="fal fa-chevron-left me-2"
                    aria-hidden="true"
                  ></i>
                </a>{" "}
                Repair
              </h2>
            </div>

            <div className="full-details">
              <form method="post" action="#">
                <div className="heading border-bottom mb-3 pb-3">
                  <span className="repair col-custom-15 text-muted fs-6">
                    Component Name
                  </span>
                  <span className="product col-custom-10 text-muted fs-6">
                    Quantity
                  </span>
                  <span className="product col-custom-12 text-muted fs-6">
                    Fail Type
                  </span>
                  <span className="customer col-custom-12 text-muted fs-6">
                    Fail Reason
                  </span>
                  <span className="customer col-custom-12 text-muted fs-6">
                    Fail Note
                  </span>
                  <span className="customer col-custom-12 text-muted fs-6">
                    Repair Note
                  </span>
                  <span className="customer col-custom-15 text-muted fs-6">
                    Proof
                  </span>
                  <span className="serial col-custom-12 text-muted fs-6 text-end">
                    Action
                  </span>
                </div>
                <div className="prduct-list">
                  {Array.apply(null, { length: loaderRepeat }).map((e, i) => (
                    <div className="card border-0 mb-2 " key={i}>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-custom-20">
                            <div className="loading">
                              <span className="line"></span>
                              <small className="">&nbsp</small>
                            </div>
                          </div>
                          <div className="col-custom-10">
                            <div className="loading">
                              <span className="line"></span>
                              <small className="mail ">&nbsp</small>
                            </div>
                          </div>
                          <div className="col-custom-15">
                            <div className="loading">
                              <span className="line"></span>
                              <small className="mail ">&nbsp</small>
                            </div>
                          </div>

                          <div className="col-custom-15">
                            <div className="loading">
                              <span className="line"></span>
                              <small className="number ">&nbsp</small>
                            </div>
                          </div>
                          <div className="col-custom-15">
                            <div className="loading">
                              <span className="line"></span>
                              <small className="number">&nbsp</small>
                            </div>
                          </div>
                          <div className="col-custom-15">
                            <div className="loading">
                              <span className="line"></span>
                              <small className="number">&nbsp</small>
                            </div>
                          </div>
                          <div className="col-custom-10">
                            <div className="loading">
                              <span className="line"></span>
                              <small className="number">&nbsp</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class ClaimSummaryLoader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const loaderRepeat = 5;
    return (
      <div className="content">
        <div className="top-bar position-static card light-gray-bg py-4">
          <div className="container-fluid">
            <div className="prduct-list">
              <div className="card border-0 bg-transparent">
                <div className="row">
                  <div className="col-custom-15">
                    <div className="">
                      <span className="id loading">
                        <span className="line"></span>
                        &nbsp
                      </span>
                      <small className="badge badge-secondary loading">
                        <span className="line"></span>
                        &nbsp
                      </small>
                    </div>
                  </div>
                  <div className="col-custom-15">
                    <span className="name loading">
                      <span className="line"></span>
                      &nbsp
                    </span>
                    <small className="mail loading">
                      <span className="line"></span>
                      &nbsp
                    </small>
                  </div>
                  <div className="col-custom-15">
                    <span className="name loading">
                      <span className="line"></span>
                      &nbsp
                    </span>
                    <small className="pro-info loading">
                      <span className="line"></span>
                      &nbsp
                    </small>
                  </div>
                  <div className="col-custom-15">
                    <span className="number loading">
                      <span className="line"></span>
                      &nbsp
                    </span>
                  </div>
                  <div className="col-custom-15">
                    <span className="text-danger loading">
                      <span className="line"></span>
                      <span className="icon text-danger">
                        <i className="fa fa-ban" aria-hidden="true"></i>
                      </span>{" "}
                      Under Warranty
                    </span>
                    <small className="loading">
                      <span className="line"></span>
                      &nbsp
                    </small>
                  </div>
                  <div className="col-custom-20">
                    <span className="text-danger loading">
                      <span className="line"></span>
                      <span className="icon text-danger">
                        <i className="fa fa-ban" aria-hidden="true"></i>
                      </span>{" "}
                      Under Warranty
                    </span>
                    <small className="loading">
                      {" "}
                      <span className="line"></span>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="detail">
          <div className="container-fluid">
            <div className="d-flex justify-content-between">
              <h2>
                <a href="/" className="txt-purple">
                  <i
                    className="fal fa-chevron-left me-2"
                    aria-hidden="true"
                  ></i>
                </a>{" "}
                Claim Summary
              </h2>
            </div>

            <div className="full-details">
              <div className="tab-col">
                <div className="card">
                  <div className="card-header bg-transparent py-0">
                    <ul
                      className="nav nav-tabs border-0 align-items-center"
                      id="myTab"
                      role="tablist"
                    >
                      <li className="nav-item border-end" role="presentation">
                        <button
                          className="nav-link border-0 py-4 txt-purple h3 disabled"
                          id="home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#repair"
                          type="button"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                        >
                          Repair Detail
                        </button>
                      </li>
                      <li className="nav-item border-end" role="presentation">
                        <button
                          className="nav-link border-0 p-4 h3  disabled"
                          id="home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#repair"
                          type="button"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                        >
                          Claim Details
                        </button>
                      </li>
                      <li
                        className="nav-item border-start ms-auto"
                        role="presentation"
                      >
                        <button
                          className="nav-link border-0 py-4 txt-purple h3 disabled"
                          id="profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#chat"
                          type="button"
                          role="tab"
                          aria-controls="profile"
                          aria-selected="false"
                        >
                          Chat
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content card-body">
                    <div className="tab-pane fade active show">
                      {/* <div className="card border-0">
                        <div className="card-header  border-0 bg-transparent pt-3 pb-0">
                          {/* <div className="row">
                            <div className="col-6">
                              <span className="mb-2 text-muted">
                                Customer Comments
                              </span>
                            </div>
                            <div className="col-6">
                              <span className="mb-2 text-muted">
                                Claim Status
                              </span>
                            </div>
                          </div> 
                        </div>
                        <div className="card-body py-4">
                          <div className="row">
                            <div className="col-6">
                              <div className="loading">
                                <span className="line"></span>
                                <span className="un-bold">&nbsp</span>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="loading">
                                <span className="line"></span>
                                <span className="un-bold d-inline-block ml-2">
                                  rejected
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <form method="post" action="#">
                        <div
                          className="heading border-bottom mb-3 pb-3"
                          style={{
                            borderBottom: "1px solid grey",
                            margin: "4px",
                          }}
                        >
                         
                            <span className="repair col-custom-20 text-muted fs-6">
                              Component Name
                            </span>
                            <span className="serial col-custom-15 text-muted fs-6">
                              Fail Type
                            </span>
                            <span className="serial col-custom-15 text-muted fs-6">
                              Action
                            </span>
                            <span className="product col-custom-10 text-muted fs-6">
                              Quantity
                            </span>
                            <span className="customer col-custom-10 text-muted fs-6">
                              Price
                            </span>
                            <span className="customer col-custom-10 text-muted fs-6">
                              Extended Price
                            </span>
                            {/* <span className="customer col-custom-20 text-muted fs-6">
                              Approved/Rejected
                            </span> */}
                          
                        </div>
                        <div className="prduct-list">
                          <div className="card border-0 mb-2 ">
                            <div className="card-body">
                              {Array.apply(null, { length: loaderRepeat }).map(
                                (e, i) => (
                                  <>
                                    <div className="row" key={i}>
                                      <div className="col-custom-20">
                                        <div className="loading">
                                          <span className="line"></span>
                                          <small className="">&nbsp</small>
                                        </div>
                                      </div>
                                      <div className="col-custom-10">
                                        <div className="loading">
                                          <span className="line"></span>
                                          <small className="mail ">&nbsp</small>
                                        </div>
                                      </div>
                                      <div className="col-custom-15">
                                        <div className="loading">
                                          <span className="line"></span>
                                          <small className="mail ">&nbsp</small>
                                        </div>
                                      </div>

                                      <div className="col-custom-15">
                                        <div className="loading">
                                          <span className="line"></span>
                                          <small className="number ">
                                            &nbsp
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-custom-15">
                                        <div className="loading">
                                          <span className="line"></span>
                                          <small className="number">
                                            &nbsp
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-custom-15">
                                        <div className="loading">
                                          <span className="line"></span>
                                          <small className="number">
                                            &nbsp
                                          </small>
                                        </div>
                                      </div>
                                      <div className="col-custom-10">
                                        <div className="loading">
                                          <span className="line"></span>
                                          <small className="number">
                                            &nbsp
                                          </small>
                                        </div>
                                      </div>
                                    </div>
                                    <hr />
                                  </>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class ClosedRepairsLoader extends React.Component {
  constructor(props) {
    super(props);
  }

  Detail;

  render() {
    const userType = auth.userType();
    const loaderRepeat = 5;
    return (
      <div className="content">
        <div className="detail">
          <div className="container-fluid">
            {/*<div className="d-flex justify-content-between">
              <h2>Completed Repairs</h2>
              <a className="btn btn-secondary text-white" href="/">
                Ongoing Repairs
              </a>
            </div>*/}
            <div className="fiter-col">
              <form className="align-items-center">
                <div className="flex-column d-flex mt-4 filter-search">
                  <div className="col-md-12">
                    <div className="row align-items-end">
                      <div className="col-md-2">
                        <label className="mb-2">Warranty Status</label>
                        <select
                          className="form-control"
                          name="warranty_status"
                          id="warranty_status"
                          disabled
                        >
                          <option value="" selected="">
                            -----
                          </option>
                          <option value="Under Warranty">Under Warranty</option>
                          <option value="Out of Warranty">
                            Out of Warranty
                          </option>
                        </select>
                      </div>
                      <div className="col-md-2">
                        <label className="mb-2">Brand</label>
                        <select
                          className="form-control"
                          name="brand"
                          id="select_brand"
                          disabled
                        >
                          <option value="">-----</option>
                          <option value="SHD">SmallHD</option>
                          <option value="TD">Teradek</option>
                        </select>
                      </div>
                      <div className="col-md-2">
                        <a className="btn ml-2 btn btn-outline-primary px-3  py-2 rounded disabled">
                          Search
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 col-md-3 text-left"></div>
                </div>
              </form>
            </div>

            <div className="full-details">
              <form method="post" action="#">
                <div className="heading">
                  <span className="repair col-custom-10 text-muted fs-6">
                    Repair #
                  </span>
                  <span className="customer col-custom-10 text-muted fs-6">
                    Customer
                  </span>
                  <span className="serial col-custom-15 text-muted fs-6">
                    Serial No.
                  </span>
                  <span className="product col-custom-15 text-muted fs-6">
                    Product
                  </span>
                  <span className="serial col-custom-15 text-muted fs-6">
                    Reference No.
                  </span>
                  <span className="serial col-custom-10 text-muted fs-6">
                    Start
                  </span>
                  <span className="serial col-custom-10 text-muted fs-6">
                    Completion
                  </span>
                  <span className="status col-custom-15 text-muted fs-6">
                    Warranty Status
                  </span>
                  {/* <span className="col-custom-15 text-muted fs-6">Repair Type</span> */}
                </div>
                <div className="prduct-list">
                  {Array.apply(null, { length: loaderRepeat }).map((e, i) => (
                    <div className="card mb-2 shadow-sm" key={i}>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-custom-10">
                            <div className="loading">
                              <span className="big-line"></span>
                              <span className="line"></span>
                            </div>
                          </div>
                          <div className="col-custom-10">
                            <div className="mail loading">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className="big-line"></span>
                            </div>
                          </div>
                          <div className="col-custom-15">
                            <div className="mail loading">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className="big-line"></span>
                            </div>
                          </div>
                          {/*userType == "c" ? (
                            <div className="col-custom-10">
                              <div className="mail loading">
                                {" "}
                                <span className="line"></span>{" "}
                                <span className="big-line"></span>
                              </div>
                            </div>
                          ) : (
                            ""
                          )*/}
                          <div className="col-custom-15">
                            <div className="number loading">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className=" big-line"></span>
                            </div>
                          </div>
                          <div className="col-custom-15">
                            <div className="number loading">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className=" big-line"></span>
                            </div>
                          </div>

                          <div className="col-custom-10">
                            <div className="mail loading">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className="big-line"></span>
                            </div>
                          </div>

                          <div className="col-custom-10">
                            <div className="mail loading">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className="big-line"></span>
                            </div>
                          </div>

                          <div className="col-custom-15">
                            <div className="loading">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className=" big-line"></span>
                            </div>
                          </div>
                          {/*<div className="col-custom-10">
                            <div className="loading small">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className=" big-line"></span>
                            </div>
                          </div>
                          <div className="col-custom-12">
                            <div className="loading small">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className=" big-line"></span>
                            </div>
                          </div>*/}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class ClaimsLoaders extends React.Component {
  constructor(props) {
    super(props);
    console.log("This is from loader.js ln 881");
  }

  Detail;

  render() {
    const userType = auth.userType();
    const loaderRepeat = 5;
    return (
      <div className="content">
        <div className="detail">
          <div className="container-fluid">
            {/*<div className="d-flex justify-content-between">
              <h2>Completed Repairs</h2>
              <a className="btn btn-secondary text-white" href="/">
                Ongoing Repairs
              </a>
            </div>*/}
            <div className="fiter-col mb-4">
              <form className="align-items-center">
                <div className=" align-items-end mt-4 filter-search">
                  <div className="">
                    <div className="row align-items-end">
                      <div className="col-md-4">
                        <div className="row align-items-center">
                          <div className="col-lg-6">
                            <label className="mb-2">Price From</label>
                            <input
                              type="text"
                              name="price_from"
                              id="price_from"
                              className="form-control"
                              value="0"
                              disabled
                            />
                          </div>
                          <div className="col-lg-6">
                            <label className="mb-2">Price To</label>
                            <input
                              type="text"
                              name="price_to"
                              id="price_to"
                              className="form-control"
                              value="999999"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <label className="mb-2">Repair Center</label>
                        <select
                          className="form-control"
                          name="dealer"
                          id="dealer"
                          disabled
                        >
                          <option value="" selected="">
                            -----
                          </option>
                          <option id="0" value="2"></option>
                          <option id="1" value="3"></option>
                          <option id="2" value="4">
                            kyra
                          </option>
                          <option id="3" value="6"></option>
                          <option id="4" value="7"></option>
                          <option id="5" value="9"></option>
                          <option id="6" value="10"></option>
                          <option id="7" value="11"></option>
                          <option id="8" value="12"></option>
                          <option id="9" value="13"></option>
                          <option id="10" value="14"></option>
                        </select>
                      </div>
                      <div className="col-md-3 text-left">
                        <a
                          href="#"
                          className="btn me-2 btn-outline-secondary py-2 px-3 rounded disabled"
                        >
                          Clear Filters
                        </a>
                        <a
                          href="#"
                          className="btn ms-2 btn btn-outline-primary px-3  py-2 rounded disabled"
                        >
                          Search
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="full-details">
              <form method="post" action="#">
                <div className="heading">
                  <span className="repair col-custom-7 text-muted fs-6">
                    Repair #
                  </span>
                  <span className="serial col-custom-12 text-muted fs-6">
                    Serial No.
                  </span>
                  {/* <span className="customer col-custom-12 text-muted fs-6">Customer</span> */}
                  <span className="product col-custom-15 text-muted fs-6">
                    Product
                  </span>
                  <span className="serial col-custom-10 text-muted fs-6">
                    Reference No.
                  </span>
                  <span className="status col-custom-7 text-muted fs-6">
                    Claim value
                  </span>
                  <span className="status col-custom-10 text-muted fs-6">
                    Claim Status
                  </span>
                  <span className="status col-custom-15 text-muted fs-6">
                    Date
                  </span>
                  <span className="status col-custom-20 text-muted fs-6">
                    Action
                  </span>
                </div>
                <div className="prduct-list">
                  {Array.apply(null, { length: loaderRepeat }).map((e, i) => (
                    <div className="card mb-2 shadow-sm" key={i}>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-custom-7">
                            <div className="loading">
                              <span className="big-line"></span>
                              <span className="line"></span>
                            </div>
                          </div>
                          <div className="col-custom-12">
                            <div className="mail loading">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className="big-line"></span>
                            </div>
                          </div>

                          {/* <div className="col-custom-12">
                            <div className="mail loading">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className="big-line"></span>
                            </div>
                          </div> */}
                          <div className="col-custom-15">
                            <div className="number loading">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className=" big-line"></span>
                            </div>
                          </div>
                          <div className="col-custom-10">
                            <div className="number loading">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className=" big-line"></span>
                            </div>
                          </div>
                          <div className="col-custom-7">
                            <div className="number loading">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className=" big-line"></span>
                            </div>
                          </div>
                          <div className="col-custom-10">
                            <div className="loading">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className=" big-line"></span>
                            </div>
                          </div>
                          <div className="col-custom-15">
                            <div className="loading">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className=" big-line"></span>
                            </div>
                          </div>
                          <div className="col-custom-20">
                            <div className="loading small">
                              <span className="line"></span>
                              &nbsp
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class ApprovalClaimsLoaders extends React.Component {
  constructor(props) {
    super(props);
  }

  Detail;

  render() {
    const userType = auth.userType();
    const loaderRepeat = 5;
    return (
      <div className="content">
        <div className="detail">
          <div className="container-fluid">
            {/*<div className="d-flex justify-content-between">
              <h2>Completed Repairs</h2>
              <a className="btn btn-secondary text-white" href="/">
                Ongoing Repairs
              </a>
            </div>*/}
            <div className="fiter-col mb-4">
              <form className="align-items-center">
                <div className=" align-items-end mt-4 filter-search">
                  <div className="">
                    <div className="row align-items-end">
                      <div className="col-md-4">
                        <div className="row align-items-center">
                          <div className="col-lg-6">
                            <label className="mb-2">Price From</label>
                            <input
                              type="text"
                              name="price_from"
                              id="price_from"
                              className="form-control"
                              value="0"
                              disabled
                            />
                          </div>
                          <div className="col-lg-6">
                            <label className="mb-2">Price To</label>
                            <input
                              type="text"
                              name="price_to"
                              id="price_to"
                              className="form-control"
                              value="999999"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <label className="mb-2">Repair Center</label>
                        <select
                          className="form-control"
                          name="dealer"
                          id="dealer"
                          disabled
                        >
                          <option value="" selected="">
                            -----
                          </option>
                          <option id="0" value="2"></option>
                          <option id="1" value="3"></option>
                          <option id="2" value="4">
                            kyra
                          </option>
                          <option id="3" value="6"></option>
                          <option id="4" value="7"></option>
                          <option id="5" value="9"></option>
                          <option id="6" value="10"></option>
                          <option id="7" value="11"></option>
                          <option id="8" value="12"></option>
                          <option id="9" value="13"></option>
                          <option id="10" value="14"></option>
                        </select>
                      </div>
                      <div className="col-md-3 text-left">
                        <a
                          href="#"
                          className="btn me-2 btn-outline-secondary py-2 px-3 rounded disabled"
                        >
                          Clear Filters
                        </a>
                        <a
                          href="#"
                          className="btn ms-2 btn btn-outline-primary px-3  py-2 rounded disabled"
                        >
                          Search
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="full-details">
              <form method="post" action="#">
                <div className="heading">
                  <span className="repair col-custom-10 text-muted fs-6">
                    Repair #
                  </span>
                  <span className="serial col-custom-15 text-muted fs-6">
                    Serial No.
                  </span>
                  {/* <span className="customer col-custom-12 text-muted fs-6">Customer</span> */}
                  <span className="product col-custom-20 text-muted fs-6">
                    Product
                  </span>
                  <span className="serial col-custom-15 text-muted fs-6">
                    Reference No.
                  </span>
                  <span className="status col-custom-10 text-muted fs-6">
                    Claim value
                  </span>
                  <span className="status col-custom-15 text-muted fs-6">
                    Date
                  </span>
                  <span className="status col-custom-15 text-muted fs-6">
                    Claim Status
                  </span>
                </div>
                <div className="prduct-list">
                  {Array.apply(null, { length: loaderRepeat }).map((e, i) => (
                    <div className="card mb-2 shadow-sm" key={i}>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-custom-10">
                            <div className="loading">
                              <span className="big-line"></span>
                              <span className="line"></span>
                            </div>
                          </div>
                          <div className="col-custom-15">
                            <div className="mail loading">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className="big-line"></span>
                            </div>
                          </div>

                          {/* <div className="col-custom-12">
                            <div className="mail loading">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className="big-line"></span>
                            </div>
                          </div> */}
                          <div className="col-custom-20">
                            <div className="number loading">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className=" big-line"></span>
                            </div>
                          </div>
                          <div className="col-custom-15">
                            <div className="number loading">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className=" big-line"></span>
                            </div>
                          </div>
                          <div className="col-custom-10">
                            <div className="number loading">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className=" big-line"></span>
                            </div>
                          </div>
                          <div className="col-custom-15">
                            <div className="number loading">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className=" big-line"></span>
                            </div>
                          </div>
                          <div className="col-custom-15">
                            <div className="loading">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className=" big-line"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class ErrorClaimsLoaders extends React.Component {
  constructor(props) {
    super(props);
  }

  Detail;

  render() {
    const userType = auth.userType();
    const loaderRepeat = 5;
    return (
      <div className="content">
        <div className="detail">
          <div className="container-fluid">
            {/*<div className="d-flex justify-content-between">
              <h2>Completed Repairs</h2>
              <a className="btn btn-secondary text-white" href="/">
                Ongoing Repairs
              </a>
            </div>*/}
            <div className="fiter-col mb-4">
              <form className="align-items-center">
                <div className=" align-items-end mt-4 filter-search">
                  <div className="">
                    <div className="row align-items-end">
                      <div className="col-md-4">
                        <div className="row align-items-center">
                          <div className="col-lg-6">
                            <label className="mb-2">Price From</label>
                            <input
                              type="text"
                              name="price_from"
                              id="price_from"
                              className="form-control"
                              value="0"
                              disabled
                            />
                          </div>
                          <div className="col-lg-6">
                            <label className="mb-2">Price To</label>
                            <input
                              type="text"
                              name="price_to"
                              id="price_to"
                              className="form-control"
                              value="999999"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <label className="mb-2">Repair Center</label>
                        <select
                          className="form-control"
                          name="dealer"
                          id="dealer"
                          disabled
                        >
                          <option value="" selected="">
                            -----
                          </option>
                          <option id="0" value="2"></option>
                          <option id="1" value="3"></option>
                          <option id="2" value="4">
                            kyra
                          </option>
                          <option id="3" value="6"></option>
                          <option id="4" value="7"></option>
                          <option id="5" value="9"></option>
                          <option id="6" value="10"></option>
                          <option id="7" value="11"></option>
                          <option id="8" value="12"></option>
                          <option id="9" value="13"></option>
                          <option id="10" value="14"></option>
                        </select>
                      </div>
                      <div className="col-md-3 text-left">
                        <a
                          href="#"
                          className="btn me-2 btn-outline-secondary py-2 px-3 rounded disabled"
                        >
                          Clear Filters
                        </a>
                        <a
                          href="#"
                          className="btn ms-2 btn btn-outline-primary px-3  py-2 rounded disabled"
                        >
                          Search
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="full-details">
              <form method="post" action="#">
                <div className="heading">
                  <span className="repair col-custom-10 text-muted fs-6">
                    Repair #
                  </span>
                  <span className="serial col-custom-10 text-muted fs-6">
                    Serial No.
                  </span>
                  {/* <span className="customer col-custom-12 text-muted fs-6">Customer</span> */}
                  <span className="product col-custom-20 text-muted fs-6">
                    Product
                  </span>
                  <span className="serial col-custom-15 text-muted fs-6">
                    Reference No.
                  </span>
                  <span className="status col-custom-10 text-muted fs-6">
                    Claim value
                  </span>
                  <span className="status col-custom-10 text-muted fs-6">
                    Approved Date
                  </span>
                  <span className="status col-custom-10 text-muted fs-6">
                    Updated Date
                  </span>
                  <span className="status col-custom-15 text-muted fs-6">
                    Claim Status
                  </span>
                </div>
                <div className="prduct-list">
                  {Array.apply(null, { length: loaderRepeat }).map((e, i) => (
                    <div className="card mb-2 shadow-sm" key={i}>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-custom-10">
                            <div className="loading">
                              <span className="big-line"></span>
                              <span className="line"></span>
                            </div>
                          </div>
                          <div className="col-custom-10">
                            <div className="mail loading">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className="big-line"></span>
                            </div>
                          </div>

                          {/* <div className="col-custom-12">
                            <div className="mail loading">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className="big-line"></span>
                            </div>
                          </div> */}
                          <div className="col-custom-20">
                            <div className="number loading">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className=" big-line"></span>
                            </div>
                          </div>
                          <div className="col-custom-15">
                            <div className="number loading">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className=" big-line"></span>
                            </div>
                          </div>
                          <div className="col-custom-10">
                            <div className="number loading">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className=" big-line"></span>
                            </div>
                          </div>
                          <div className="col-custom-10">
                            <div className="number loading">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className=" big-line"></span>
                            </div>
                          </div>
                          <div className="col-custom-10">
                            <div className="number loading">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className=" big-line"></span>
                            </div>
                          </div>
                          <div className="col-custom-15">
                            <div className="loading">
                              {" "}
                              <span className="line"></span>{" "}
                              <span className=" big-line"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class RepairScreenLoader extends React.Component {
  constructor(props) {
    super(props);
  }

  Detail;

  render() {
    const userType = auth.userType();
    const loaderRepeat = 5;
    return (
      <div className="content">
        <div className="top-bar position-static card light-gray-bg py-4">
          <div className="container-fluid">
            <div className="prduct-list">
              <div className="card bg-transparent border-0">
                <div className="row">
                  <div className="col-custom-15">
                    <div className="">
                      <span className="id loading mb-2">
                        <span className="line"></span>
                        &nbsp
                      </span>
                      <small className="badge badge-secondary loading">
                        <span className="line"></span>
                        &nbsp
                      </small>
                    </div>
                  </div>
                  <div className="col-custom-20">
                    <span className="name loading">
                      <span className="line"></span>
                      &nbsp
                    </span>
                    <small className="mail loading">
                      <span className="line"></span>
                      &nbsp
                    </small>
                  </div>
                  <div className="col-custom-15">
                    <span className="name loading">
                      <span className="line"></span>
                      &nbsp
                    </span>
                    <small className="pro-info loading">
                      <span className="line"></span>
                      &nbsp
                    </small>
                  </div>
                  <div className="col-custom-15">
                    <span className="number loading">
                      <span className="line"></span>
                      &nbsp
                    </span>
                  </div>
                  <div className="col-custom-15">
                    <span className="text-danger loading">
                      <span className="line"></span>
                      <span className="icon text-danger">
                        <i className="fa fa-ban" aria-hidden="true"></i>
                      </span>{" "}
                      Under Warranty
                    </span>
                    <small className="loading">
                      {" "}
                      <span className="line"></span>
                      &nbsp
                    </small>
                  </div>
                  <div className="col-custom-20">
                    <span className="text-danger loading">
                      <span className="line"></span>
                      <span className="icon text-danger">
                        <i className="fa fa-ban" aria-hidden="true"></i>
                      </span>{" "}
                      Under Warranty
                    </span>
                    <small className="loading">
                      {" "}
                      <span className="line"></span>
                      &nbsp
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="detail">
          <div className="container-fluid">
            <div className="d-flex justify-content-between">
              <h2>
                {" "}
                <a href="/" className="txt-purple">
                  <i
                    className="fal fa-chevron-left me-2"
                    aria-hidden="true"
                  ></i>
                </a>
                Replace or Repair parts
              </h2>
            </div>

            <div className="full-details">
              <form method="post" action="#">
                <div className="heading">
                  <span className="repair col-custom-15 text-muted fs-6">
                    Component Name
                  </span>
                  <span className="customer col-custom-12 text-muted fs-6">
                    Fail Type
                  </span>
                  <span className="customer col-custom-12 text-muted fs-6">
                    Fail Reason
                  </span>
                  <span className="customer col-custom-12 text-muted fs-6">
                    Fail Note
                  </span>
                  <span className="customer col-custom-10 text-muted fs-6">
                    Repair Note
                  </span>
                  <span className="customer col-custom-15 text-muted fs-6">
                    Proof
                  </span>
                  <span className="product col-custom-3 text-muted fs-6 text-end" />
                  <span className="product col-custom-20 text-muted fs-6 text-end">
                    Action
                  </span>
                </div>
                <div className="prduct-list">
                  {Array.apply(null, { length: loaderRepeat }).map((e, i) => (
                    <div
                      className="card border-0 border-bottom mb-2 bg-transparent"
                      key={i}
                    >
                      <div className="card-body">
                        <div className="row">
                          <div className="col-custom-30">
                            <div className="loading h-100">
                              <span className="line"></span>
                              <span>&nbsp</span>
                              <small>&nbsp</small>
                            </div>
                          </div>
                          <div className="col-custom-40">
                            <div className=" loading h-100">
                              <span className="line"></span>
                              <span className="mail">&nbsp</span>
                            </div>
                          </div>
                          <div className="col-custom-30">
                            <div className="btn-col loading h-100">
                              <span className="line"></span>
                              <a className="btn">
                                <i
                                  className="fa fa-wrench"
                                  aria-hidden="true"
                                ></i>{" "}
                                Repair
                              </a>
                              <a className="btn">
                                <i
                                  className="fa fa-wrench"
                                  aria-hidden="true"
                                ></i>{" "}
                                Replace
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class HelpLoader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const userType = auth.userType();
    const loaderRepeat = 5;
    return (
      <div className="content">
        <div className="detail">
          <div className="container-fluid">
            <div className="full-details">
              <form method="post" action="#">
                <div className="prduct-list">
                  {Array.apply(null, { length: loaderRepeat }).map((e, i) => (
                    <div className="info-bar" key={i}>
                      <div className="col-15">
                        <small className="loading">
                          <span className="big-line"></span>
                          <span className="line"></span>
                        </small>
                      </div>
                      <div className="col-15">
                        <small className="mail loading">
                          {" "}
                          <span className="line"></span>{" "}
                          <span className="big-line"></span>
                        </small>
                      </div>
                      <div className="col-15">
                        <small className="mail loading">
                          {" "}
                          <span className="line"></span>{" "}
                          <span className="big-line"></span>
                        </small>
                      </div>
                      <div className="col-15">
                        <small className="number loading">
                          {" "}
                          <span className="line"></span>{" "}
                          <span className=" big-line"></span>
                        </small>
                      </div>
                      <div className="col-15">
                        <small className="number loading">
                          {" "}
                          <span className="line"></span>{" "}
                          <span className=" big-line"></span>
                        </small>
                      </div>
                      <div className="col-15">
                        <small className="number loading">
                          {" "}
                          <span className="line"></span>{" "}
                          <span className=" big-line"></span>
                        </small>
                      </div>
                      <div className="col-10 text-right float-right">
                        <small className="loading">
                          {" "}
                          <span className="line"></span>{" "}
                          <span className=" big-line"></span>
                        </small>
                      </div>
                    </div>
                  ))}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class ItemLoader extends React.Component {
  render() {
    const loaderRepeat = 5;
    return (
      <div className="content">
        {/* <div className="detail"> */}
        {/* <div className="container-fluid"> */}
        <div className="full-details">
          <div className="prduct-list">
            {Array.apply(null, { length: loaderRepeat }).map((e, i) => (
              <div className="card border-0 mb-2 shadow-sm" key={i}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-1">
                      <div className="number loading">
                        <span className="big-line"></span>
                        <span className="line"></span>
                      </div>
                    </div>
                    <div className="col-1">
                      <div className="loading">
                        {" "}
                        <span className="line"></span>{" "}
                        <span className=" big-line"></span>
                      </div>
                    </div>
                    <div
                      className={`${
                        auth.userType() === "r"
                          ? "col-custom-10"
                          : "col-custom-10"
                      }`}
                    >
                      <div className="d-block loading">
                        <span className="big-line"></span>
                        <span className="line"></span>
                      </div>
                      <div className="loading">
                        <span className="medium-line"></span>
                        <span className="line"></span>
                      </div>{" "}
                    </div>
                    <div
                      className={`${
                        auth.userType() === "r"
                          ? "col-custom-5"
                          : "col-custom-5"
                      }`}
                    >
                      <div className="d-block loading">
                        <span className="big-line"></span>
                        <span className="line"></span>
                      </div>
                    </div>
                    <div
                      className={`${
                        auth.userType() === "r"
                          ? "col-custom-5"
                          : "col-custom-5"
                      }`}
                    >
                      <div className="d-block loading">
                        <span className="big-line"></span>
                        <span className="line"></span>
                      </div>
                    </div>
                    <div
                      className={`${
                        auth.userType() === "r"
                          ? "col-custom-5"
                          : "col-custom-5"
                      }`}
                    >
                      <div className="d-block loading">
                        <span className="big-line"></span>
                        <span className="line"></span>
                      </div>
                    </div>
                    <div
                      className={`${auth.userType() === "r" ? "col" : "col"}`}
                    >
                      <div className="loading">
                        <span className="big-line"></span>
                        <span className="line"></span>
                      </div>{" "}
                    </div>
                    <div
                      className={`${auth.userType() === "r" ? "col" : "col "}`}
                    >
                      <div className="loading">
                        {" "}
                        <span className="line"></span>{" "}
                        <span className=" big-line"></span>
                      </div>
                    </div>
                    <div
                      className={`${auth.userType() === "r" ? "col" : "col"}`}
                    >
                      <div className="number loading">
                        {" "}
                        <span className="line"></span>{" "}
                        <span className=" big-line"></span>
                      </div>
                    </div>
                    <div
                      className={`${auth.userType() === "r" ? "col" : "col"}`}
                    >
                      <div className="number loading">
                        {" "}
                        <span className="line"></span>{" "}
                        <span className=" big-line"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export class ProductsLoader extends React.Component {
  render() {
    const loaderRepeat = 5;
    return (
      <div className="content">
        {/* <div className="detail"> */}
        {/* <div className="container-fluid"> */}
        <div className="full-details">
          <div className="prduct-list">
            {Array.apply(null, { length: loaderRepeat }).map((e, i) => (
              <div className="card border-0 mb-2 shadow-sm" key={i}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-1">
                      <div className="number loading">
                        <span className="big-line"></span>
                        <span className="line"></span>
                      </div>
                    </div>
                    <div className="col-1">
                      <div className="loading">
                        {" "}
                        <span className="line"></span>{" "}
                        <span className=" big-line"></span>
                      </div>
                    </div>
                    <div
                      className={`${
                        auth.userType() === "r"
                          ? "col-custom-10"
                          : "col-custom-10"
                      }`}
                    >
                      <div className="d-block loading">
                        <span className="big-line"></span>
                        <span className="line"></span>
                      </div>
                      <div className="loading">
                        <span className="medium-line"></span>
                        <span className="line"></span>
                      </div>{" "}
                    </div>
                    <div
                      className={`${
                        auth.userType() === "r"
                          ? "col-custom-5"
                          : "col-custom-5"
                      }`}
                    >
                      <div className="d-block loading">
                        <span className="big-line"></span>
                        <span className="line"></span>
                      </div>
                    </div>
                    <div
                      className={`${
                        auth.userType() === "r"
                          ? "col-custom-5"
                          : "col-custom-5"
                      }`}
                    >
                      <div className="d-block loading">
                        <span className="big-line"></span>
                        <span className="line"></span>
                      </div>
                    </div>
                    <div
                      className={`${
                        auth.userType() === "r"
                          ? "col-custom-5"
                          : "col-custom-5"
                      }`}
                    >
                      <div className="d-block loading">
                        <span className="big-line"></span>
                        <span className="line"></span>
                      </div>
                    </div>
                    <div
                      className={`${auth.userType() === "r" ? "col" : "col"}`}
                    >
                      <div className="loading">
                        <span className="big-line"></span>
                        <span className="line"></span>
                      </div>{" "}
                    </div>
                    <div
                      className={`${auth.userType() === "r" ? "col" : "col "}`}
                    >
                      <div className="loading">
                        {" "}
                        <span className="line"></span>{" "}
                        <span className=" big-line"></span>
                      </div>
                    </div>
                    <div
                      className={`${auth.userType() === "r" ? "col" : "col"}`}
                    >
                      <div className="number loading">
                        {" "}
                        <span className="line"></span>{" "}
                        <span className=" big-line"></span>
                      </div>
                    </div>
                    <div
                      className={`${auth.userType() === "r" ? "col" : "col"}`}
                    >
                      <div className="number loading">
                        {" "}
                        <span className="line"></span>{" "}
                        <span className=" big-line"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export class PeopleLoader extends React.Component {
  render() {
    const loaderRepeat = 5;
    return (
      <div className="content">
        {/* <div className="detail"> */}
        {/* <div className="container-fluid"> */}
        <div className="full-details">
          <div className="prduct-list">
            {Array.apply(null, { length: loaderRepeat }).map((e, i) => (
              <div className="card border-0 mb-2 shadow-sm" key={i}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-1">
                      <div className="number loading">
                        <span className="big-line"></span>
                        <span className="line"></span>
                      </div>
                    </div>
                    <div className="col-1">
                      <div className="loading">
                        {" "}
                        <span className="line"></span>{" "}
                        <span className=" big-line"></span>
                      </div>
                    </div>
                    <div
                      className={`${
                        auth.userType() === "r"
                          ? "col-custom-10"
                          : "col-custom-10"
                      }`}
                    >
                      <div className="d-block loading">
                        <span className="big-line"></span>
                        <span className="line"></span>
                      </div>
                      <div className="loading">
                        <span className="medium-line"></span>
                        <span className="line"></span>
                      </div>{" "}
                    </div>
                    <div
                      className={`${
                        auth.userType() === "r"
                          ? "col-custom-5"
                          : "col-custom-5"
                      }`}
                    >
                      <div className="d-block loading">
                        <span className="big-line"></span>
                        <span className="line"></span>
                      </div>
                    </div>
                    <div
                      className={`${
                        auth.userType() === "r"
                          ? "col-custom-5"
                          : "col-custom-5"
                      }`}
                    >
                      <div className="d-block loading">
                        <span className="big-line"></span>
                        <span className="line"></span>
                      </div>
                    </div>
                    <div
                      className={`${
                        auth.userType() === "r"
                          ? "col-custom-5"
                          : "col-custom-5"
                      }`}
                    >
                      <div className="d-block loading">
                        <span className="big-line"></span>
                        <span className="line"></span>
                      </div>
                    </div>
                    <div
                      className={`${auth.userType() === "r" ? "col" : "col"}`}
                    >
                      <div className="loading">
                        <span className="big-line"></span>
                        <span className="line"></span>
                      </div>{" "}
                    </div>
                    <div
                      className={`${auth.userType() === "r" ? "col" : "col "}`}
                    >
                      <div className="loading">
                        {" "}
                        <span className="line"></span>{" "}
                        <span className=" big-line"></span>
                      </div>
                    </div>
                    <div
                      className={`${auth.userType() === "r" ? "col" : "col"}`}
                    >
                      <div className="number loading">
                        {" "}
                        <span className="line"></span>{" "}
                        <span className=" big-line"></span>
                      </div>
                    </div>
                    <div
                      className={`${auth.userType() === "r" ? "col" : "col"}`}
                    >
                      <div className="number loading">
                        {" "}
                        <span className="line"></span>{" "}
                        <span className=" big-line"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
export class UsersLoader extends React.Component {
  render() {
    const userNumber = 5;
    return (
      <div>
        <div className="container-fluid prduct-list">
          <div className="to-do mt-4">
            <div className="card mb-3 info-bar p-0">
              <div className="card-header border-0 d-flex justify-content-between align-items-center pt-4 pb-4">
                <h4 className="mb-0" style={{ color: "#323267" }}>
                  <small className="number loading">
                    {" "}
                    <span className="line"></span>{" "}
                    <span className=" big-line"></span>
                  </small>
                </h4>
                <a
                  className="btn  font-weight-light"
                  href="#addUser"
                  data-bs-toggle="modal"
                  data-bs-target="#add-modal"
                  style={{ color: "#214D9A" }}
                >
                  <small className="number loading">
                    {" "}
                    <span className="line"></span>{" "}
                    <span className=" big-line"></span>
                  </small>
                </a>
              </div>
              <div className="card-body heading border-bottom">
                <div className="row">
                  <div className="col-md-1">
                    <p className="mb-0 text-muted">Id</p>
                  </div>
                  <div className="col-md-2">
                    <p className="mb-0 text-muted">Name</p>
                  </div>
                  <div className="col-md-3">
                    <p className="mb-0 text-muted">Login Id</p>
                  </div>
                  <div className="col-md-2">
                    <p className="mb-0 text-muted">Role</p>
                  </div>
                  <div className="col-md-1">
                    <p className="mb-0 text-muted">Status</p>
                  </div>
                  {/* <div
                    className={`${
                      this.props.role == 3 ? "col-md-1" : "col-md-2"
                    }`}
                  >
                    <p className="mb-0 text-muted">Brand</p>
                  </div>
                  <div className="col-md-2">
                    <p className="mb-0 text-muted">Location</p>
                  </div> */}
                  {<div className="col-md-1"></div>}
                </div>
              </div>
              {Array.apply(null, { length: userNumber }).map((e, i) => (
                <div className="card-body" key={i}>
                  <div className="row py-3 row-hover">
                    <div className="col col-md-1">
                      <div className="small-screen d-block d-md-none">Id</div>
                      <p className="mb-0 font-weight-light">
                        <small className="number loading">
                          {" "}
                          <span
                            className="line"
                            style={{ maxWidth: "70%" }}
                          ></span>{" "}
                          <span className=" big-line"></span>
                        </small>
                      </p>
                    </div>
                    <div className="col col-md-2">
                      <div className="small-screen d-block d-md-none">Name</div>
                      <p className="mb-0 font-weight-light">
                        <small className="number loading">
                          {" "}
                          <span className="line"></span>{" "}
                          <span className=" big-line"></span>
                        </small>
                      </p>
                    </div>
                    <div className="col col-md-3">
                      <div className="small-screen d-block d-md-none">
                        Login Id
                      </div>
                      <p className="mb-0 font-weight-light">
                        <small className="number loading">
                          {" "}
                          <span className="line"></span>{" "}
                          <span className=" big-line"></span>
                        </small>
                      </p>
                    </div>
                    <div className="col col-md-2">
                      <div className="small-screen d-block d-md-none">Role</div>
                      <p className="mb-0 font-weight-light">
                        <small className="number loading">
                          {" "}
                          <span className="line"></span>{" "}
                          <span className=" big-line"></span>
                        </small>
                      </p>
                    </div>
                    <div className="col col-md-1">
                      <div className="small-screen d-block d-md-none">
                        Status
                      </div>
                      <p className="mb-0 font-weight-light">
                        <small className="number loading">
                          {" "}
                          <span
                            className="line"
                            style={{ maxWidth: "70%" }}
                          ></span>{" "}
                          <span className=" big-line"></span>
                        </small>
                      </p>
                    </div>
                    <div
                      className={`col ${
                        this.props.role == 3 ? "col-md-1" : "col-md-2"
                      }`}
                    >
                      <div className="small-screen d-block d-md-none">
                        Brand
                      </div>
                      <p className="mb-0 font-weight-light">
                        <small className="number loading">
                          {" "}
                          <span
                            className="line"
                            style={{ maxWidth: "70%" }}
                          ></span>{" "}
                          <span className=" big-line"></span>
                        </small>
                      </p>
                    </div>
                    <div className="col col-md-2">
                      <div className="small-screen d-block d-md-none">
                        Location
                      </div>
                      <p className="mb-0 font-weight-light">
                        <small className="number loading">
                          {" "}
                          <span className="line"></span>{" "}
                          <span className=" big-line"></span>
                        </small>
                      </p>
                    </div>
                    {this.props.role == 3 ? (
                      <div className="col col-md-1">
                        <div className="small-screen d-block d-md-none">
                          Edit
                        </div>
                        <p className="mb-0 font-weight-light">
                          <small className="number loading">
                            {" "}
                            <span
                              className="line"
                              style={{ maxWidth: "50%" }}
                            ></span>{" "}
                            <span className=" big-line"></span>
                          </small>
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class HeaderLoader extends React.Component {
  render() {
    return (
      <div>
        <div
          id="result"
          className="p-3 d-flex justify-content-center align-items-center"
          style={{
            color: "#360066",
            width: "100%",
          }}
        >
          <i className="fa fa-spin fa-spinner" style={{ fontSize: "4em" }}></i>
        </div>
      </div>
    );
  }
}

export class RepairStartLoader extends React.Component {
  render() {
    return (
      <div>
        <div className="container-fluid prduct-list">
          <div className="to-do mt-4">
            <div className=" mb-3 info-bar p-0">
              <div className="col col-md-1">
                <div className="small-screen d-block d-md-none">Status</div>
                <p className="mb-0 font-weight-light">
                  <small className="number loading">
                    {" "}
                    <span
                      className="line"
                      style={{ maxWidth: "70%" }}
                    ></span>{" "}
                    <span className=" big-line"></span>
                  </small>
                </p>
              </div>
            </div>
            <div className=" mb-3 info-bar p-0">
              <div className="card-body">
                <div className="col col-md-1">
                  <div className="small-screen d-block d-md-none">Status</div>
                  <p className="mb-0 font-weight-light">
                    <small className="number loading">
                      {" "}
                      <span
                        className="line"
                        style={{ maxWidth: "70%" }}
                      ></span>{" "}
                      <span className=" big-line"></span>
                    </small>
                  </p>
                </div>
              </div>
            </div>
            <div className=" mb-3 info-bar p-0">
              <div className="card-body">
                <div className="col col-md-1">
                  <div className="small-screen d-block d-md-none">Status</div>
                  <p className="mb-0 font-weight-light">
                    <small className="number loading">
                      {" "}
                      <span
                        className="line"
                        style={{ maxWidth: "70%" }}
                      ></span>{" "}
                      <span className=" big-line"></span>
                    </small>
                  </p>
                </div>
              </div>
            </div>
            <div className=" mb-3 info-bar p-0">
              <div className="-body">
                <div className="col col-md-1">
                  <div className="small-screen d-block d-md-none">Status</div>
                  <p className="mb-0 font-weight-light">
                    <small className="number loading">
                      {" "}
                      <span
                        className="line"
                        style={{ maxWidth: "70%" }}
                      ></span>{" "}
                      <span className=" big-line"></span>
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class BomLoader extends React.Component {
  render() {
    const loaderRepeat = 5;
    return (
      <div>
        <div className="content">
          {/* <div className="detail"> */}
          {/* <div className="container-fluid"> */}
          <div className="full-details">
            <div className="prduct-list">
              {Array.apply(null, { length: loaderRepeat }).map((e, i) => (
                <div
                  className="info-bar row align-items-center pl-0 pr-0"
                  key={i}
                >
                  <div className="col">
                    <small className="number loading">
                      <span className="big-line"></span>
                      <span className="line" style={{ maxWidth: "50%" }}></span>
                    </small>
                  </div>
                  <div
                    className={`${auth.userType() === "r" ? "col-10" : "col "}`}
                  >
                    <small className="loading">
                      {" "}
                      <span className="line"></span>{" "}
                      <span className=" big-line"></span>
                    </small>
                  </div>
                  <div className={`${auth.userType() === "r" ? "col" : "col"}`}>
                    <span className="d-block loading">
                      <span className="big-line"></span>
                      <span className="line"></span>
                    </span>
                    <small className="loading">
                      <span className="medium-line"></span>
                      <span className="line"></span>
                    </small>{" "}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        );
      </div>
    );
  }
}
