import React, { useEffect, useState } from "react";
import { AlgoliaSearcher } from "../utils/Search";
import DataSearch from "../layout/DataSearch";

function AlgoliSearch({
  searchdata = "",
  indexName,
  datahandler,
  filter = "",
  page = 0,
}) {
  let [searchvalue, setSearchValue] = useState(searchdata);

  let searchtexthandler = (search) => {
    let searchresult = AlgoliaSearcher(
      search,
      indexName,
      filter ? filter : "",
      page
    );
    datahandler(searchresult); // prop function to handle index data 
  };
  useEffect(() => {
    searchtexthandler(searchdata);
  }, []);
  useEffect(() => {
    searchtexthandler(searchdata);
  }, [page]);
  return (
    <div>
      <DataSearch
        childcomponent={
          <input
            className="form-control"
            name={indexName}
            id="search"
            value={searchvalue}
            type="text"
            onChange={(e) => {
              setSearchValue(e.target.value);
             
            }}
            onSubmit={(e)=>{console.log('submit action'); searchtexthandler(e.target.value);}}
          />
        }
      />
    </div>
  );
}

export default AlgoliSearch;
