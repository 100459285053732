import react, {Component} from 'react';
import {BsArrowLeft, BsArrowRight} from 'react-icons/bs';

export default class Paginator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNo: this.props.pageNo || 1,
            perPage: this.props.perPage || 10,
            totalItems: this.props.totalItems,
            tabs: 10,
            tabArray: [],
            totalTabs: 0,
            isStartDisable: true,
            isEndDisable: false
        }

        this.setTabs = this.setTabs.bind(this);
        this.nextTabs = this.nextTabs.bind(this);
        this.prevTabs = this.prevTabs.bind(this);
    }

    componentDidMount() {
        this.setTabs(1, 10);
    }

    setTabs = (start, end) => {
        // console.log(start, end)
        this.setState({pageNo: start});
        const tabs = Array(end - start + 1).fill().map((_, idx) => start + idx);
        const totaltabs = Math.ceil(this.state.totalItems / this.state.perPage);
        this.setState({
            tabArray: tabs.slice(0, totaltabs),
            totalTabs: totaltabs,
        })
        this.props.handlePageChange({perPage: this.state.perPage,pageNo: start});
    }

    nextTabs = () => {
        // console.log("called next");
        const currEnd = Math.ceil(this.state.pageNo) ;
        // console.log(this.state.pageNo)
        if (currEnd + 10 < this.state.totalTabs) {
            this.setTabs(currEnd+1, currEnd+10);
        } else if (currEnd + 10 > this.state.totalTabs && currEnd + 10 != this.state.totalTabs) {
            this.setTabs(currEnd+1, this.state.totalTabs);
            this.setState({tabs: this.state.totalTabs % 10});
        } else {
            this.setState({isEndDisable: true});
        }
        this.setState({isStartDisable: false});
    }

    prevTabs = () => {
        // console.log("called prev");
        const currStart = Math.floor(this.state.pageNo) ;
        if (currStart != 1) {
            this.setTabs(currStart - 1, currStart+8);
        } else {
            this.setState({isStartDisable: true})
        }
    }

    prevTenTabs = () => {
        const currStart = Math.floor(this.state.pageNo / 10) *10;
        if (!(currStart - 10 < 0)) {
            this.setTabs(currStart - 9, currStart);
        } else {
            this.setState({isStartDisable: true})
        }
    }

    nextTenTabs = () => {
        const currEnd = Math.ceil(this.state.pageNo /10) *10;
        if (currEnd + 10 < this.state.totalTabs) {
            this.setTabs(currEnd+1, currEnd+10);
        } else if (currEnd + 10 > this.state.totalTabs && currEnd + 10 != this.state.totalTabs) {
            this.setTabs(currEnd+1, this.state.totalTabs);
            this.setState({tabs: this.state.totalTabs % 10});
        } else {
            this.setState({isEndDisable: true});
        }
        this.setState({isStartDisable: false});
    }
    render() {
        return  <nav className="itemsPage" aria-label="Page navigation example">
        <ul className="pagination justify-content-center mt-4">
            <li className="page-item d-flex align-items-start mr-2">
                <a className="page-link" style={{width: "100%"}} onClick={this.prevTenTabs}>
                    {`<<`}
                </a>
                <a className="page-link" style={{width: "100%"}} onClick={this.prevTabs}>
                    <BsArrowLeft />
                </a>
            </li>
            
            {this.state.tabArray.map((el, i) => (
                <li className={this.state.pageNo && this.state.pageNo==el ? 'page-item active' : 'page-item'} key={i}>
                    <a className="page-link" onClick={() => {
                        this.setState({pageNo: el});
                        this.props.handlePageChange({perPage: this.state.perPage,pageNo: el});
                    }}>
                        {Number(el)}
                    </a>
                </li>
            ))}
            
            <li className="page-item d-flex align-items-start ml-2">
                <a className="page-link" style={{width: "100%"}} onClick={this.nextTabs}>
                    <BsArrowRight />
                </a>
                <a className="page-link" style={{width: "100%"}} onClick={this.nextTenTabs}>
                    {`>>`}
                </a>
            </li>
        </ul>
    </nav>
    }
}