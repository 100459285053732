const TOKEN_KEY = '_crc_token';
const USER_KEY = '_crc_user';
const USER_ROLE = '_crc_role';
const USER_TYPE = '_crc_user_type';
const USER_ID = '_crc_user_id';
const USER_NOTIFICATIONS = '_crc_user_notifications';
const CURRENT_COMPANY_ID = '_crc_current_company_id';


function user() {

    let user = window.localStorage.getItem(USER_KEY);

    try {
        user = JSON.parse(user);
    } catch (e) {
        user = false;
    }

    return user;
}

function token() {
    return window.localStorage.getItem(TOKEN_KEY) || false;
}

function setUser(user, user_id) {
    window.localStorage.setItem(USER_KEY, JSON.stringify(user));
    window.localStorage.setItem(USER_ID, user_id);    
}

function setToken(token) {
    window.localStorage.setItem(TOKEN_KEY, token);
}

function setLogin(token, user, user_id, role_id = null) { 
    setUser(user, user_id);
    setToken(token);
}

function setRole(role_id, user_type) { 
    window.localStorage.setItem(USER_ROLE, role_id);
    window.localStorage.setItem(USER_TYPE, user_type);
}
function setNotifications (notification) {
    window.localStorage.setItem(USER_NOTIFICATIONS, notification)
   }

function userRole() { 
    let role_id = window.localStorage.getItem(USER_ROLE);
    return role_id ;
}

function userId() { 
    let user_id = window.localStorage.getItem(USER_ID);
    return user_id ;
}

function userType(){
    let usertype = window.localStorage.getItem(USER_TYPE);
    return usertype ;
}

function user_Company_or_Reseller_Filter(){
    let usertype = userType()
    let filter=''
    if(usertype=='c'){
        filter=`company_id=${currentCompanyId()}`
    }else if( usertype=='r'){
       filter=`reseller_id=${user()['resellers']['0']['company_id']}`
    }
    return filter ;
}

function isLoggedIn() {
    //return user() && token();
    return token();
}
function userNotifications () {
    return window.localStorage.getItem(USER_NOTIFICATIONS)
}

function setcurrentCompanyId(currentCompanyId) {    
    window.localStorage.setItem(CURRENT_COMPANY_ID, currentCompanyId);
}

function currentCompanyId() { 
    let currentCompanyId = window.localStorage.getItem(CURRENT_COMPANY_ID);
    return currentCompanyId ;
}
  

export default {user_Company_or_Reseller_Filter, user, token, setUser, setToken, setLogin, setRole, setNotifications, isLoggedIn, userRole,userId, userType, userNotifications, setcurrentCompanyId, currentCompanyId};