import appconfig from '../../../config';
const BASE_URL = appconfig.BASE_URL;
import auth from '../../auth';
import http from '../../http';
import HelpLoader from '../loaders'

export default class Help extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showArticle: false,			
			articles : '',
			searchError : false,
			searchValue : '', 
			helpType : 0 //1= APP Guid, 2= Monitor , 3= search
		};
	}

	getHelpArticles = (e, searchValue = null) => {
		let helpType = this.state.helpType
		let monitorId = ''
		let searchType = ''
		let local_company_id=localStorage.getItem('_crc_current_company_id');
		let repair_id=localStorage.getItem('repair_id');
		if(!searchValue){
			searchType = e.target.getAttribute('data-help')		
			monitorId = this.props.monitorId
		}	
		if(helpType == searchType || (helpType == 3 && searchValue)){
			return false
		}
		// const appGuide = this.props.appGuide
		let appGuide = document.getElementsByName('APP_GUIDE')[0].getAttribute('content')
		const session_id = auth.isLoggedIn();		
		this.setState({ 				
			articles : '',
			showArticle : false
		}) 
		http.get('help/manuals',  {   
			params: {
                session_id, monitorId,searchValue,appGuide,helpType: searchType,local_company_id:local_company_id,repair_id:repair_id
            }      
		}
		 ).then((res) => {  
			const respData = res.data
			 this.setState({ 				
				articles : respData.data,
				showArticle : true,
				helpType : respData.helpType
			}) 
							
		 }).catch((error) => {
			if(error.response && error.response.status === 401){ 
				//empty user session
				auth.setLogin('', '')
				window.location.href = BASE_URL+'/login.html';
			}
		 }) 
	}

	handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault()
		let searchError = false
		let searchValue = e.target.value
		searchValue = searchValue.trim()
		  if(!searchValue){			  
			searchError = true,
			searchValue
		  }
		  this.setState({
			searchError,
			searchValue
		  })
		  this.getHelpArticles(e,searchValue)
		}
	  }

	searchArticle = (e) => {
		e.preventDefault()
		let searchValue = document.getElementById('search-article').value
		searchValue = searchValue.trim()
		let searchError = false
		  if(!searchValue){			  
			searchError = true
			
		  }
		  this.setState({
			searchError,
			searchValue
		  })	
		  this.getHelpArticles(e,searchValue)	  
	}

	helpClose = () => {
		document.body.classList.remove("click-help");
		document.body.classList.remove("expand-click-help");
	}

    render() {

        return (
	   
           <div className="right-slide-col">	
			<div className="outer">
				<div className="tab-content" id="myTabContent">
					<div className="tab-pane fade show active" id="help" role="tabpanel" aria-labelledby="help-tab">					
					<div className=" heading-col">
						<div className='d-flex justify-content-between align-items-center'>
							<h3><i className="fa fa-life-ring" aria-hidden="true"></i><span className="text">Help</span></h3>
							<h3>
								<a className='me-2 text-dark' onClick={()=>{
									document.body.classList.remove('click-help');
									document.body.classList.add('expand-click-help');
									
								}}><i className="fas fa-expand-wide"></i></a>
							<a className='me-2  text-dark' 
							onClick={()=>{
								document.body.classList.remove('expand-click-help');
								document.body.classList.add('click-help');
								
							  }}><i className="fas fa-compress-wide"></i></a>
								<a className="me-2 text-dark" onClick={this.helpClose}><i className="far fa-times"></i></a>
							</h3>
						</div>
						{/* <form className="search">
							<fieldset>
								<input id="search-article" className={this.state.searchError ? 'border-danger' : ''} type="search" placeholder="homepage" onKeyDown={this.handleKeyDown}/>
								<button onClick={this.searchArticle}><i className="fa fa-search" aria-hidden="true"></i></button>
							</fieldset>
						</form> */}
					</div>
						<ul className="nav nav-tabs" id="myTab" role="tablist">
								<li className={ "nav-item " + ( this.props.monitorId ? ' w-50' : ' w-100' )} role="presentation">
									<a className="nav-link text-center active" id="guide-tab" data-bs-toggle="tab" href="#guide" role="tab" aria-controls="guide" aria-selected="true" data-help="1" onClick={this.getHelpArticles}>App Guide</a>
								</li>
								{this.props.monitorId  ? 
								<li className="nav-item w-50" role="presentation">
									<a className="nav-link text-center" id="mon-tab" data-bs-toggle="tab" href="#mon" role="tab" aria-controls="mon" aria-selected="true" data-help="2" onClick={this.getHelpArticles}>{this.props.monitorName}</a>
								</li>
								:''
								}
							</ul>
								{this.state.showArticle ? <>
								{this.state.articles && this.state.articles.length ?
									<div className="py-4">
									<div  className="accordion" id="accordionExample">
									{this.state.articles.map((chapterData, i) =>(
										this.state.helpType === 3 ? 
										chapterData ? 
										<div className="card" key={i}>
										
									  <div className="card-body">
											
												<h3>{chapterData.article.title}</h3>
												 <div 
												dangerouslySetInnerHTML= {{ __html:chapterData.article.html_body}}
												/>
											</div>
										
									</div>
									: '':

										<div className="card" key={i}>
											<div className="card-header" id="heading3">
												<h2 className="mb-0">
													<button className="btn btn-link btn-block text-left d-flex justify-content-between align-items-center" type="button" data-bs-toggle="collapse" aria-expanded="false"  data-bs-target={"collapse"+(i)} onClick={()=>{
														document.getElementById("collapse"+(i)).className==""?document.getElementById("collapse"+(i)).className="collapse":document.getElementById("collapse"+(i)).className=""
													}}  aria-controls={"collapse"+(i)}>
													{this.state.helpType ==  1 ? 
													<>
													{chapterData.title.split('__').pop()}
													</>
													:
													chapterData.title}<i className="fa fa-chevron-down"></i>
													</button>
												</h2>
											</div>
											<div id={"collapse"+(i)} className="collapse" aria-labelledby="heading3" data-parent={"#accordionExample"}>
      									<div className="card-body">
													
													
													{chapterData.articleData && chapterData.articleData.length ?
													<>
													{chapterData.articleData.map((articleContent,a) => (
													<>	
													{articleContent.article && articleContent.article.html_body ?
													<>
													<h3>{articleContent.article.title}</h3>
													 <div key={a}  
													dangerouslySetInnerHTML= {{ __html:articleContent.article.html_body}}
													/>
													</>
														:
													''}												
													
													</>)
													)}
													</>
													:
													<p>No content found</p>
													}
												</div>
											</div>
										</div>						
									))}
									</div>
									</div>	
									:
									<div className="card">
									<div className="card-body">
									<h3 className="">No data found</h3>
									</div>
									</div>
									
								}
								</>
								:
								
								<div className=" my-4">											
      									<div className="">
													<h2 className="loading">{'\u00A0'}
													<span className="line"></span></h2>
													<p className="postion-relative loading">
														test
														<span className="line"></span>
													</p>
													<p className="loading">
														test
														<span className="line"></span>
													</p>
													<p className="loading">
														test
														<span className="line"></span>
													</p>
													<div className="video loading">
														<span className="line"></span>
														<iframe src=""></iframe>
													</div>
											</div>
										</div>
								}
						
					</div>
				</div>
			</div>			
		</div>		
        );

    }

}
