import React, { useEffect } from "react";
import AlgoliSearch from "../Components/AlgoliSearch";
import GlobalSearch from "../Components/GlobalSearch"
import DataList from "../layout/DataList";
import DataPage from "../layout/DataPage";
import { useState } from "react";
import DataRow from "../layout/DataRow";
import DataColumn from "../layout/DataColumn";
import { search_index } from "../data/globalsearchdata.json";
import { filter_objectbykey, filter_objectlist } from "../utils/object";
import DataHeading from "../layout/DataHeading";
import DataSearch from "../layout/DataSearch";
import DataPagination from "../layout/DataPagination";
import DataNotFound from "../layout/DataNotFound";
import DataProgressLoader from "../layout/DataProgressLoader";
import {formatHeading} from "../utils/object"
function ViewPage({
  data='',
  indexName,
  search = "",
  childcomponent,
  filter='',
}) {
  let [searchresult, setSearchresult] = useState([]);
  let [paginationcomponent,setPaginationComponent]=useState(<></>)
  let [currentpage,setCurrentpage]=useState(0)
  let [showloader,setShowloader]=useState(true)
  let pagehandler=(selectedpage)=>{
    setCurrentpage(selectedpage)
  }
  let agolia_searchresult = (data) => {
    setShowloader(true)
    data
      .then(({ hits,nbPages,page }) => {
        setSearchresult(hits);
        let page_numbers=[]
        for(let i=0;i<nbPages;i++){
            page_numbers.push(i)
        }
        setPaginationComponent(<DataPagination nbPageslist={page_numbers} page={page} pagehandler={pagehandler} />)
        setShowloader(false)
      })
      .catch((e) => {setSearchresult([]);setShowloader(false)});
  };
  return (
    <>
      <DataPage
        childcomponent={
          <>
           <GlobalSearch
              searchdata={data}
              indexName={indexName}
              datahandler={agolia_searchresult}
              filter={filter}
              page={currentpage}
            />
           {searchresult && searchresult.length>0 && 
           <>
            <DataList
            heading={search_index[indexName]["fields"].map(header=>formatHeading(header))}
            childcomponent={searchresult.map((row) => (
              <DataRow
                redirecturl={row.redirect_url}
                childcomponent={
                  <DataColumn
                    data={filter_objectbykey(
                      row,
                      search_index[indexName]["fields"]
                    )}
                  />
                }
              />
            ))}
          />
          {paginationcomponent}
           </>
          }
          {
            searchresult && searchresult.length==0 ? showloader?<DataProgressLoader/>:
            <DataNotFound/>:null
          }
          </>
        }
      />
    </>
  );
}

export default ViewPage;
