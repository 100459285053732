import React, { useEffect } from 'react'
import appconfig from "../../config";
const BASE_URL = appconfig.BASE_URL;
import DataList from '../layout/DataList'
import {search_index,headerControl} from '../data/globalsearchdata.json'
import DataRow from '../layout/DataRow'
import DataColumn from '../layout/DataColumn'
import {filter_objectbykey} from '../utils/object'
import DataNotFound from '../layout/DataNotFound'
import {formatHeading} from '../utils/object'
function SearchResultDropDown({data,indexName,searchvalue,custom_message}) {
  return (
   <>
    {data && data.length>0 && 
        <>
 <DataList
            heading={search_index[indexName]["search_fields"].map(header=>formatHeading(header))}
            childcomponent={data.map((row) => (
              <DataRow
                redirecturl={row.redirect_url}
                childcomponent={
                  <DataColumn
                  
                    data={filter_objectbykey(
                      row,
                      search_index[indexName]["search_fields"]
                    )}
                  />
                }
              />
            ))}
          />
 {headerControl.map(({url,index,tag})=>
  index==indexName?
  <a href={`${
    BASE_URL +
    url +
    index +
    "=" +
  searchvalue
  }`}>
<div className="p-2 d-flex justify-content-center align-items-center">
  
  <button type="button" class="btn btn-outline-primary listSearch" 
  style={{ background: "linear-gradient(45deg, #110D5B 0%, #64086B 100%)",color:'white',width:'270px'}} >View All</button>
  </div>
  </a>:null
  

 )}
    </>
    } 
    {
            data && data.length==0 ?<DataNotFound message={custom_message}/>:null
          }
   </>
  )
}

export default SearchResultDropDown