let get_objectkeys=(object)=>{ //returns object keys as an array 
    return Object.keys(object)
}
let filter_objectbykey=(given_object,keys_filter)=>{ //returns object after filter
    let filtered_object={}
    for (let key of keys_filter){
        if(keys_filter.includes(key)){
            filtered_object[key]=given_object[key]
        }
    }
    return filtered_object
}
let filter_objectlist=(given_object_list,objectfilter)=>{
    let filtered_object_list=[]
    for (let object of given_object_list){
        // console.log(object[Object.keys(objectfilter)[0]],objectfilter[Object.keys(objectfilter)[0]])
        if(object[Object.keys(objectfilter)[0]]==objectfilter[Object.keys(objectfilter)[0]]){
                    return object
                }
    }
    // filtered_object_list=given_object_list.map(object=>{
    //     console.log(object[Object.keys(objectfilter)[0]],objectfilter[Object.keys(objectfilter)[0]])
    //     if(object[Object.keys(objectfilter)[0]]==objectfilter[Object.keys(objectfilter)[0]]){
    //         return object
    //     }
        
    // }
    // )
 

    return filtered_object_list
}
let sort_object=(given_object,asc_desc=1)=>{
    let result={}
    given_object=Object.entries(given_object);
    given_object=given_object.sort((a,b)=>asc_desc>0?b[1]-a[1]:a[1]-b[1]).map(data=>result[data[0]]=data[1])
return result
}

let formatHeading=(header)=> {
    return header.replace(/_/g, ' ').replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

export {get_objectkeys,filter_objectbykey,filter_objectlist,sort_object,formatHeading};
