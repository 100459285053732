import React from "react";

function DataSearch({ childcomponent,datahandler }) {
  return (
    <div className="fiter-col">
    <form className="align-items-center">
      <div className="d-flex flex-column mt-4 filter-search">
        <div className="col-md-12">
          <div className="row align-items-end justify-content-between">
            <div className="col-md-12">
              <div className="row align-items-end">
                <div className="col-md-12">
                  <div className="d-flex">
                    <label className="mb-2">Search</label>
                    <a className="ms-1 text-muted"
                      ><div>
                        <span
                          ><i
                            className="fas fa-question-circle"
                            color="secondary"
                            id="Popover-warranty_status"
                        /></span>
                        </div>
                        </a>
                  </div>
                 {childcomponent}
                </div>
              
              </div>
            </div>
          </div>
        </div>
      
      </div>
    </form>
  </div>
         
      
  );
}

export default DataSearch;
