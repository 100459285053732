import React from 'react';
import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';

class PopoverItem extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false
    };
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  }

  render() {
    return (
      <span onClick={this.toggle}>
          {this.props.item.type=='help'?<i className="fas fa-question-circle" color="secondary" id={'Popover-' + this.props.id} onClick={this.toggle}></i>:
        <Button className="mr-1" color="secondary" id={'Popover-' + this.props.id} onClick={this.toggle}>
          {this.props.item.title}
        </Button>}
        <Popover placement={this.props.item.position} isOpen={this.state.popoverOpen} target={'Popover-' + this.props.id} toggle={this.toggle}>
          <PopoverHeader>{this.props.item.title}</PopoverHeader>
          <PopoverBody>{this.props.item.content}</PopoverBody>
        </Popover>
      </span>
    );
  }
}
{/* <span>
{this.props.item.type?<i className="far fa-question"></i>:
<Button className="mr-1" color="secondary" id={'Popover-' + this.props.id} onClick={this.toggle}>
{this.props.item.title}
</Button>}
<Popover placement={this.props.item.position} isOpen={this.state.popoverOpen} target={'Popover-' + this.props.id} toggle={this.toggle}>
<PopoverHeader>this.props.item.title</PopoverHeader>
<PopoverBody>this.props.item.content</PopoverBody>
</Popover>
</span> */}
class PopoverExampleMulti extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      popovers: [
        {
          position: props.position,
          title:props.title,
          content: props.content,
          type:props.type
        },
        // {
        //   placement: 'bottom',
        //   text: 'Bottom'
        // },
        // {
        //   placement: 'left',
        //   text: 'Left'
        // },
        // {
        //   placement: 'right',
        //   text: 'Right'
        // }
      ],
      id:props.id
    };
  }

  render() {
    return (
      <div>
        { this.state.popovers.map((popover, i) => {
          return <PopoverItem key={i} item={popover} id={this.state.id} />;
        })}
      </div>
    );
  }
}

export default PopoverExampleMulti;