import React from 'react'
import http from '../../providers/http';
import { CSVDownload ,CSVLink} from "react-csv";
import {useState} from "react";
import auth from '../../providers/auth';
function Repairclaims(props) {
    let [downloaddata,setDownloadabledata]=useState([])
    let [dlink,setLink]=useState(false);
    let fetchdata=()=>{
        // alert("coming soon");
        let data=["fsdf","sdfsdf","dsfsdf","dsfsdf"];
        const session_id = auth.isLoggedIn();
        let url=props.url?props.url:'repairclaimsbyreseller'
        let table,condition
        if( props.url=='viewdata'){
           table=props.table
           condition=props.condition
        }
        // alert(props.company_id)
        http.get(url,{
            params: {
                session_id,
                company_id:auth.userType()=='c'?window.localStorage.getItem('_crc_current_company_id'):props.company_id?props.company_id:0,
                table:table,
                condition:condition
            }
        })
        .then((data)=>{
            // console.log(data)
            return data.data;
            
        })
        
        .then((data)=>{
            //    console.log(data);

                setDownloadabledata(data.data?data.data:data);
                
               if(downloaddata!==[]){
        
                //    console.log("==>",downloaddata)
                   setLink(true)
            
                   setTimeout(()=>{
                    setLink(false)
                   },10000)
               }else{
                // console.log(downloaddata)
               }
           })
    }
    return (
        <><div className="d-block text-end align-content-end">
             <span className="btn ml-3 py-2 btn-outline-danger rounded" onClick={()=>fetchdata()}>Download {props.displaytext?props.displaytext:" Repair Claims"}</span>
<br/>
    {dlink?<CSVLink data={downloaddata} filename={`${props.displaytext?props.displaytext:" Repair Claims"}${new Date()}`} target="_blank" > <small> <b>click here </b>  to download  {props.displaytext}</small></CSVLink>:null}
</div>
        </>
       
    )
            }
export default Repairclaims