import React from 'react'
import { get_objectkeys } from "../utils/object";
function DataHeading({data}) {
  return (
    <div className="heading">
      <span className={`text-muted fs-6 repair col-custom-${100 / get_objectkeys(data).length}`}></span>
      {get_objectkeys(data).map((key) => (
          <span className={`text-muted fs-6 repair col-custom-${100 / get_objectkeys(data).length}`}>{data[key]}</span>
      ))}
    </div>
  );
}

export default DataHeading