import React, { useEffect,useState } from "react";

function DataPagination({ nbPageslist,page,pagehandler,pagelimit=5}) {
  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination justify-content-center mt-4">
      {page!=0?<li className={`page-item`} onClick={()=>{
        pagehandler(page-1);
    }
    }><a className="page-link"><i className="fa fa-solid fa-arrow-left"></i></a></li>:null}

     {[...nbPageslist.slice(page<=3?0:page-3,page+4)].map(pagenumber=>
     <li className={`page-item ${page==pagenumber?' active':''}`} onClick={()=>{
        pagehandler(pagenumber);
    }
    }><a className="page-link">{pagenumber+1}</a></li>
     )}
      {
        nbPageslist.length>4 && page!=(nbPageslist.length-1)?
        <li className={`page-item`} onClick={()=>{
          pagehandler(page+1);
      }
      }><a className="page-link">
        <i className="fa fa-solid fa-arrow-right"></i></a></li>:null
      }
     

      </ul>
    </nav>
  );
}

export default DataPagination;
