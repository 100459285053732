export default class Navbar extends React.Component {
  render() {
    return (
      <div className="navbar-collapse" id="bs-navbar">
        <ul className="nav">
          <li>
            <a href="#">
              <i className="fa fa-wrench" aria-hidden="true"></i> Repair
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa fa-cart-plus" aria-hidden="true"></i> Order Parts
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa fa-list-ul" aria-hidden="true"></i> credit memos
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fa fa-cog" aria-hidden="true"></i> Setting
            </a>
          </li>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownMenuLink"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Companies
            </a>
            <div
              className="dropdown-menu p-0 border-0"
              aria-labelledby="navbarDropdownMenuLink"
            >
              {this.props.resellerCompanies ? (
                <>
                  {this.props.resellerCompanies.map((company, ci) => {
                    return (
                      <a
                        href="#"
                        className={
                          company.company_id == this.props.currentCompany
                            ? "list-group-item list-group-item-action active"
                            : "list-group-item list-group-item-action"
                        }
                        key={"c" + ci}
                        onClick={this.props.selectCompany}
                        data-company-id={company.company_id}
                        data-entity-type={company.entity_type}
                      >
                        {company.company_name}
                      </a>
                    );
                  })}
                </>
              ) : (
                ""
              )}
            </div>
          </li>
        </ul>
      </div>
    );
  }
}
